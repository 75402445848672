.MailUsContainer {
  display: flex;
flex-direction: row;
  /* flex-direction: row !important; */
  justify-content: space-between;
  /* padding: 11.8097vw 5.4882vw 10.175vw 6.3313vw; */
  padding: 11.8097vw 5.4632vw 10.1750vw 5.7736vw;
  /* padding-top: 21.8299vw; */
  /* align-items: center; */
}

/* :horizontal */
.MailUsContentContainer {
  width: 36.8056vw;
  /* flex: 1; */
  /* display: flex;  */
  /* Make it a flex container */
  /* flex-direction: row; */
   /* Stack items vertically */
  /* padding-top: 15.7819vw; */
  margin-top: 6.8097vw;
  /* margin-right: ; */
  /* position: fixed; */
}

.MailUsContentTitle {
  /* width: 3.2639vw; */
  font-family: "Public Sans";
  font-size: 2.2222vw;
  font-weight: 600;
  line-height: 3.2446vw;
  text-align: left;
  margin: 0;
  color: rgba(251, 176, 59, 1);
}

.MailUsContentST {
  font-family: "DM Sans";
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 2.0278vw;
  text-align: left;
  margin: 0;
  color: rgba(38, 50, 56, 1);
}
/* 
  .MailUsContentST{
    font-family: "DM Sans";
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 2.0278vw;
  text-align: left;
  color: #263238;
  } */

.MailUsFormLabel {
  font-family: "DM Sans";
  font-size: 1.3889vw !important;
  /* font-size: 1.3889vw; */
  font-weight: 600;
  line-height: 2.8472vw;
  text-align: left;
  color: rgba(73, 73, 73, 1);
}

.compulsaryLabel {
  font-family: "DM Sans";
  font-size: 1.6667vw;
  font-weight: 400;
  line-height: 1.7167;
  text-align: left;
  color: #b13337;
}

.MailUsFormField {
  margin-bottom: 0.5056vw;
  flex: 1;
}

.requiredFileds {
  font-family: "DM Sans";
  font-size: 0.9722vw;
  font-weight: 500;
  line-height: 1.9931vw;
  text-align: left;
  color: #b13337;
  padding: 0.2vw 0 0.9vw 0.5vw;
}

.requiredFiledsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.formInput {
  /* border: null; */
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 37.1875vw;
  padding-bottom: 0vw;
  border-color: #dedede;
  font-family: "DM Sans";
  font-size: 1.1889vw;
  font-weight: 500;
  line-height: 1.5833vw;
  text-align: left;
  color: var(--KRZ-color-sub-content-topic, #737373);
  outline: none;
}

.inputError {
  border-bottom: 0.1389vw solid #b13337;
}

.error {
  color: #b13337;
  font-size: 1.0417vw;
  margin: 0.3472vw 0 0 0;
  font-family: "DM Sans";
  font-weight: 500;
  line-height: 2.1354vw;
  text-align: left;
}

.suberror {
  font-family: "DM Sans";
  font-weight: 500;
  line-height: 1.566vw;
  text-align: left;

  color: #b13337;
  font-size: 0.7639vw;
  margin: 0.3472vw 0 0 0;
}
/* 
.formAttachContainer{
  margin-top: 10vw !important;
} */

/* mobile designs */

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: light-dark(
    rgb(255, 255, 255),
    rgb(255, 255, 255)
  ) !important;
  color: #737373 !important;
}


input:-webkit-autofill {
  appearance: menulist-button; /* Sets appearance similar to a select dropdown */
  background-image: none !important;
  background-color: rgb(255, 255, 255) !important; /* Sets a light background */
  -webkit-text-fill-color: #737373 !important; /* Sets text color to a medium gray */
  transition: background-color 5000s ease-in-out 0s; /* Prevents sudden color change */
}


.statusMsg {
  display: flex;
  flex-direction: row;
  padding-left: 1vw;
}
.tickImg {
  width: 1.5278vw;
  height: 1.5278vw;
  margin-top: 1.25vw;
}
.formMsg {
  font-family: "DM Sans";
  font-size: 1.3889vw;
  line-height: 2.8472vw;
  color: green;
font-weight: 600;
  margin-top: 0.65vw;
  padding-left: 1vw;
  margin-bottom: 0;
}

@media (max-width: 440px) {
  .MailUsContainer {
    flex-direction: column !important;
    margin-top: 13.7889vw;
    /* padding-bottom: 25vw; */
    padding-bottom: 0;
    /* margin-bottom: 4.7722vw !important; */
    /* width: 89.2167vw;
height: 80.2028vw; */
  }

  input:-webkit-autofill {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #737373 !important; /* Light color for text */
  }
  .MailUsContentContainer {
    width: 89.2167vw;
    margin-top: 0;
    width: 89.2167vw;
    margin-top: 0;
    /* height: 80.2028vw; */
  }
  .MailUsContentTitle {
    width: 89.2167vw;
    height: 9.0111vw;
    font-size: 5.5556vw;
    font-family: "Lexend";
    font-weight: 500;
    line-height: 6.9444vw;
  }
  .MailUsContentST {
    width: 89.2167vw;
    height: 5vw;
    font-size: 3.8889vw;
    line-height: 4.8667vw;
    margin-top: 0.5556vw;
  }
  .mailForm {
    padding: 4.7222vw 0vw 4.7722vw;
    width: 89.2167vw;
    /* height: 45.6389vw; */
    height: fit-content;
  }
  .compulsaryLabel {
    font-size: 4.4444vw;
    font-weight: 400;
    line-height: 9.1111vw;
  }
  .error {
    font-size: 2.7778vw;
    padding-top: 1.3889vw;
    margin: 0;
  }

  .MailUsFormLabel {
    /* font-size: 3.8889vw;
    font-weight: 600;
    line-height: 6.8333vw; */
    font-family: "DM Sans" !important;
    font-size: 3.3333vw !important;
    /* font-size: 1.3889vw; */
    font-weight: 600;
    line-height: 6.8333vw !important;
    text-align: left;
    color: rgba(73, 73, 73, 1);
  }
  .requiredFiledsContainer {
    display: none;
  }
  .MailUsFormField {
    width: 87.6694vw;

    margin-bottom: 5.8333vw;
    padding-top: 4.4444vw;
  }
  .formInput {
    width: 85vw;
    font-size: 3.8889vw;
  }
  /* .whyKRZButton {
    margin-top: 6.6667vw !important;
    width: 19.4444vw !important;
    height: 8.6111vw !important;
    font-family: "Public Sans" !important;
    font-weight: 600 !important;
    font-size: 3.8889vw !important;
    border-radius: 0.8333vw !important;
    margin-left: 69.7722vw !important;
  } */

  .fileuploadcancelContainer{
    
    font-size: 2.2222vw;
    
    line-height: 4.5556vw;
    
    margin-top: 2.1139vw;
    margin-bottom: 0.7139vw;
      }

      .tickImg {
        width: 4vw;
        height: 4vw;
        margin-top: 1.3vw;
      }
      .formMsg {
        font-size: 3.0222vw;
        line-height: 4.5556vw;
        color: green;
      
        margin-top: 1.3vw;
        padding-left: 1vw;
        margin-bottom: 0;
      }
     
}



/* 
@media (min-width: 441px) {
  .MailUsContainer {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    padding: 11.8097vw 5.4882vw 10.175vw 6.3313vw;
   
  }
}
@media screen and (min-width: 1520px) {
} */
