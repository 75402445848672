.footerContainer {
  background: #fff7ec;
  width: 100%;
  height: 23.525vw;
  padding: 2.6389vw 0 1.6667vw 0;
}

.footerContentContainer {
  height: 15.3451vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logoFooter {
  width: 9.1368vw;
  height: 5.5979vw;
  margin-left: 3.2667vw;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.locationContainer {
  width: 23.9583vw;
  height: 10.5556vw;
  margin-top: 1.3757vw;
}

.locationimage {
  width: 1.2965vw;
  height: 1.8521vw;
  margin-right: 0.5vw;
}

.location {
  font-family: "Lexend";
  font-size: 1.6667vw;
  font-weight: 600;
  line-height: 2.0833vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--KRZ-color-Title-color, #3f3f3f);
}

.address {
  font-family: "DM Sans";
  font-size: 1.1111vw;
  font-weight: 500;
  line-height: 2.2778;
  text-align: left;
  margin: 1.4583vw 0 0 1.9611vw;
  color: var(--KRZ-color-Sub-content-color, #242626);
}

.footerRoutingContainer {
  list-style-type: none;
  padding: 0;
  margin-top: 1.3757vw;
  margin-left: 9.1667vw;
  margin-right: 4.2361vw;
}

.routeList {
  font-family: "DM Sans";
  font-size: 1.1111vw;
  font-weight: 500;
  line-height: 2.2778vw;
  text-align: left;
  margin-bottom: 1.6243vw;
  cursor: pointer;
  color: var(--KRZ-color-Sub-content-color, #242626);
}

.routeList:hover {
  color: var(--KRZ-color-Primary-color, #fbb03b);
}

.copyright {
  margin-bottom: 0.9743vw;
  font-family: "DM Sans";
  font-size: 1.1111vw;
  font-weight: 600;
  line-height: 1.4465vw;
  text-align: center;
  margin-top: 6.0417vw;
  color: #757575;
}

@media screen and (max-width: 440px) {
  .footerContainer {
    height: 80vw;
    /* height: 94.4750vw !important; */
  }

  .footerContentContainer {
    flex-direction: column;
    height: fit-content;
  }

  .footerRoutingContainer {
    margin-left: 3.9083vw;
  }

  .logoFooter {
    width: 20.5889vw;
    height: 12.3917vw;
    margin-left: -1vw;
  }

  .locationContainer {
    width: 91.725vw;
    margin-left: 3.9083vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .routeList {
    font-size: 3.3333vw;
    line-height: 6.8333vw;
    font-weight: 300;
  }

  .locationimage {
    width: 2.9167vw;
    height: 4.1667vw;
    margin-top: 0.7vw;
  }

  .address {
    font-size: 2.7778vw;
    font-weight: 300;
    line-height: 5.6944vw;
    margin: 0 0 5.5139vw 1.8583vw;
  }

  .copyright {
    font-size: 2.2222vw;
    line-height: 4.5556vw;
  }
}

@media screen and (min-width: 1520px) {
  .footerContainer {
    height: fit-content;
    
  }
  .footerContentContainer {
    height: fit-content;
  }
  .logoFooter {
    width: 160.67px;
    height: 5.5979vw;
    margin-left: 3.2667vw;
  }

  .locationimage {
    width: 18.67px;
    height: 26.67px;
  }

  .location {
    font-size: 24px;

    line-height: 30px;
  }

  .address {
    font-size: 16px;

    line-height: 32.8px;

    width: 311px;
    height: 99px;
    margin-left: 32px;
  }
  .routeList {
    font-size: 16px;

    line-height: 32.8px;
    margin-bottom: 23.39px;
  }

  .copyright {
    font-size: 16px;

    line-height: 20.83px;
    margin-top: 77px;
    /* margin-bottom: 38.03px; */
  }
}
