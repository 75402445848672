.whyKRZContainer {
  width: 100%;
  height: fit-content;
  padding-top: 10.9201vw;
  overflow: hidden;
  position: relative;
}

.whyKRZbanner {
  width: 100%;
  height: 49.959vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4.5vw;
}

.whyKRZSideRendering {
  width: 49.959vw;
  height: 49.959vw;
  /* position: absolute; */
}

.whyKRZimageContainer {
  /* width: 49.9590vw;
    height: 49.9590vw;
    
    padding-top: 10.9201vw; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
}

.subwhyKRZContentContainer {
  width: 65.7757vw;
  height: 13.4972vw;
  text-align: left;
  margin-left: 4.0903vw;
  margin-top: 0;
  position: relative;
}

.whyKRZBannerMainContent {
  font-family: 'Lexend';
  font-size: 3.3333vw;
  font-weight: 800;
  line-height: 4.1667vw;
  text-align: left;
  margin: 0 0 2.2222vw 0;
  color: var(--KRZ-color-Title-color, #3f3f3f);
}

.whyKRZBannerSupportContent {
  margin: 0;
  font-family: 'DM Sans';
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 3.0556vw;
  text-align: left;
  color: var(--KRZ-color-Sub-content-color, #242626);
}

.ICEContainer {
  width: 91.6667vw;
  padding: 0 0 4.5vw 3.7125vw;
}

.ICETitle {
  font-family: 'Lexend';
  font-size: 3.3333vw;
  font-weight: 600;
  line-height: 4.8667vw;
  text-align: left;
  margin: 0 0 3.3333vw 0;
  width: 47.384vw;
  color: var(--KRZ-color-Title-color, #3f3f3f);
}

.ICEGalleryContainer {
  /* width: 100%; */
  height: 50.4944vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.ICEG0 {
  width: 29.4444vw;
  height: 24.6556vw;
  position: absolute;
  top: 0;
  left: 0;
}

.ICEG2 {
  width: 38.2222vw;
  height: 24.6556vw;
  position: absolute;
  left: 30.6vw;
}

.ICEG1 {
  width: 36.8222vw;
  height: 24.6556vw;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ICEG3 {
  width: 30.7944vw;
  height: 24.6556vw;
  position: absolute;
  /* bottom: -57vw; */
  /* top:50%; */
  left: 38vw;
  bottom: 0vw;
}

.ICEG4 {
  width: 21.6267vw;
  height: 50.4944vw;
  position: absolute;
  right: 0;
}

.pics {
  width: 100%;
  height: 100%;
}

.ICEGCStyle {
  font-family: 'DM Sans';
  font-size: 1.3889vw;
  font-weight: 700;
  line-height: 2vw;
  text-align: left;
  color: #ffffff;
  margin-left: 5%;
  position: absolute;
}

.ICEGCHLStyle {
  color: var(--KRZ-color-Primary-color, #fbb03b);
}

.ICEGCStyle0 {
  width: 58.5%;
  top: 0;
  margin-top: 6.4%;
}

.ICEGCStyle1 {
  top: 0;
  width: 88.4%;
  margin-top: 6.4%;
}

.ICEGCStyle3 {
  top: 0;
  width: 81.3%;
  margin-top: 6.4%;
}

.ICEGCStyle2 {
  top: 0;
  width: 81.3%;
  margin-top: 6.4%;
}

.ICEGCStyle4 {
  bottom: 0;
  width: 93%;
  margin-bottom: 6.5%;
}

.coreContainer {
  /* background: url("/public/pics/coreBG.svg"); */
  width: 100%;
  height: 88.3333vw;
  position: relative;
  /* background-repeat: no-repeat; */
}

.coreContainerSideBG {
  position: absolute;
  right: -15.9889vw;
  width: 100%;
  height: 88.3333vw;
  /* float: right; */
  /* right: 0; */
}

.coreContent {
  position: absolute;
  width: 89.7917vw;
  margin-left: 5.1vw;
  margin-right: 5.1vw;
  height: fit-content;
}

.coreTitle {
  width: 100%;
  text-align: center;
  font-family: 'Lexend';
  font-size: 3.3333vw;
  font-weight: 600;
  line-height: 4.8667vw;
  text-align: center;
  color: var(--KRZ-color-Title-color, #3f3f3f);
  margin-bottom: 3.3333vw;
  margin-top: 6.5944vw;
}

.corePointsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.corePointaAlignment1 {
  align-items: flex-start;
}

.corePointaAlignment2 {
  align-items: flex-end;
}

.pointsHeading {
  font-family: 'Lexend';
  font-size: 2.5vw;
  font-weight: 600;
  line-height: 3.0556vw;
  text-align: left;
  color: var(--KRZ-color-Primary-color, #fbb03b);
  width: 39.5833vw;
  margin: 0 0 2.2222vw 0;
}

.points {
  font-family: 'DM Sans';
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 3.0556vw;
  text-align: left;
  width: 39.5833vw;
  color: var(--KRZ-color-Sub-content-color, #242626);
  margin: 0 0 3.3333vw 0;
}

@media (max-width: 440px) {
  .whyKRZBannerMainContent {
    font-size: 5.5556vw;
    line-height: 6.9444vw;
    width: 50.3361vw;
  }
  .whyKRZBannerSupportContent {
    font-size: 3.8889vw;
    width: 77vw;
    line-height: 6.2917vw;
    padding-top: 2.2222vw;
  }

  .whyKRZimageContainer {
    padding-left: 13vw;
    margin-top: 15vw;
    transform: rotate(3deg);
  }
  .whyKRZSideRendering {
    height: 58.2vw;
    width: 58.2vw;
  }
  .ICEContainer {
    padding-top: 30vw;
    width: 91vw;
    height: 120vw;
  }
  .ICEGalleryContainer {
    height: 120vw;
  }
  .ICETitle {
    font-size: 5.5556vw;
    width: 79.7944vw;
    line-height: 6.9444vw;
  }
  .ICEG0 {
    width: 39.6528vw;
    height: 36.0944vw;
  }
  .ICEG1 {
    width: 46.225vw;
    height: 35.6806vw;
    top: 36.5vw;
  }
  .ICEG2 {
    width: 50.1306vw;
    height: 36.0944vw;
    left: 40.9vw;
  }
  .ICEG3 {
    width: 43.5639vw;
    height: 35.6917vw;
    top: 36.5vw;
    left: 47.4vw;
  }
  .ICEG4 {
    width: 91.1111vw;
    height: 39.925vw;

    margin-top: 72.5vw;
  }
  .coreTitle {
    font-size: 5.5556vw;
    line-height: 8.1111vw;
    text-align: left;
    padding-top: 11vw;
  }
  .pointsHeading {
    text-align: center;
    font-size: 4.4444vw;
    width: 90vw;
    height: 7.4vw;
    padding-top: 11vw;
    /* line-height: 12.2222; */
  }
  .points {
    font-size: 3.8889vw;
    height: 33.25vw;
    width: 90vw;
    line-height: 6.2917vw;
    text-align: center;
  }
  .coreContainer {
    padding-bottom: 130vw;
  }
  .ICEGCStyle {
    width: 35.775vw;
    height: 11.5vw;
    font-size: 2.7vw;
    line-height: 4vw;
  }
  .ICEGCStyle4 {
    font-size: 3.3333vw;
    line-height: 4.8vw;
    margin: 0 0 0 2vw;
  }
}
