:root{
  --animation-duration: '8.510s';
  --text-change-duration: '0.55s'
}


.newBannerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 800px; */
  height: 100vh;
  position: relative;
  overflow: hidden;
  /* margin: 11.0153vw 20.0285vw 9.3625vw 17.0472vw; */
}

.newBannerCenContContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%;
height:100%; */
}
.cenConTextCont {
  position: absolute;
  top: -2.3611vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cenContImage {
  position: absolute;
  width: 55.7792vw;
  height: 32.8882vw;
  z-index: -2;
}

.cenConTextCont_MainCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  /* top:20px; */
}

.cenConTextCont .cenConTextCont_MainCont .newBannerccMainfont {
  font-family: 'Lexend';
  /* font-size: 24px;
font-weight: 800;
line-height: 30px;
text-align: left; */
  font-size: 1.2267vw;
  line-height: 2.0833vw;
  text-align: left;
  width: 24.6167vw;
  font-weight: 800;
  margin: 0;
  color: #3f3f3f;
}

.newBannerHLContainer {
  width: 12.3194vw;
  height: 2.0389vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* margin-left: 0.17vw; */
  overflow: hidden;
}

.cenConTextCont
  .cenConTextCont_MainCont
  .newBannerHLContainer
  .newBannerccMainHighlight {
  font-family: 'Lexend';

  font-weight: 800;
  line-height: 2.0833vw;
  font-size: 1.2267vw;
  color: #fff;
  /* width: 11.3194vw; */
}

.cenConSubText {
  font-family: 'DM Sans';
  /* font-size: 16px; */
  font-size: 0.9111vw;
  font-weight: 400;
  /* line-height: 24px; */
  line-height: 1.6667vw;
  text-align: center;
  margin: 0;
  width: 32.0243vw;
  color: #3f3f3f;
  margin-left: 2vw;
}

@keyframes scrollUp {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes scrollUpp {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.scroll-up {
  animation: scrollUp var(--text-change-duration) ease-out;
}

.scroll-upp {
  animation: scrollUpp var( --text-change-duration) ease-out;
}

.hidden {
  opacity: 0;
}

.newBannerDesign1 {
  background: #ef930345;

  animation: nbannerDesign1 var(--animation-duration) linear infinite;
  position: absolute;
  rotate: -21.85deg;
  z-index: -4;
}

@keyframes nbannerDesign1 {
  0%,
  13.5% {
    border-radius: 1.9444vw;
    top: 2.5625vw;
    left: 29.2319vw;
    width: 8.0153vw;
    height: 7.0056vw;
  }

  25%,
  38.5% {
    width: 7.9215vw;
    height: 7.4868vw;
    top: 3.9299vw;
    left: 20.6319vw;
    border-radius: 5.5028vw;
  }

  50%,
  63.5% {
    width: 7.9215vw;
    height: 7.4868vw;
    top: 7.3764vw;
    left: 10.8993vw;
    border-radius: 0;
  }

  75%,
  88.5% {
    width: 26.9292vw;
    height: 7.4868vw;
    top: 12.0153vw;
    left: -9.5812vw;
    border-radius: 0;
  }

  100% {
    border-radius: 1.9444vw;
    top: 2.5625vw;
    left: 29.2319vw;
    width: 8.0153vw;
    height: 7.0056vw;
  }
}

.newBannerDesign2 {
  background: #ef930345;
  animation: nbannerDesign2 var(--animation-duration) linear infinite;
  position: absolute;
  rotate: -21.85deg;
  overflow: hidden;
  z-index: -4;
}

@keyframes nbannerDesign2 {
  0%,
  13.5% {
    top: 11.7576vw;
    right: 10.1354vw;
    width: 8.0153vw;
    height: 7.0056vw;
    border-radius: 2.5vw;
  }

  25%,
  38.5% {
    width: 8.2562vw;
    height: 7.9958vw;
    top: 6.3986vw;
    right: 14.284vw;
    border-radius: 5.5028vw;
  }

  50%,
  63.5% {
    width: 6.5153vw;
    height: 6.0056vw;
    top: 7.1764vw;
    right: 20.709vw;
    border-radius: 0;
  }

  75%,
  88.5% {
    width: 35.1271vw;
    height: 4.834vw;
    top: 9.709vw;
    right: -9.4278vw;
    border-radius: 0;
  }

  100% {
    top: 11.7576vw;
    right: 10.1354vw;
    width: 8.0153vw;
    height: 7.0056vw;
    border-radius: 2.5vw;
  }
}

.newBannerDesign3 {
  background: #ef930345;

  animation: nbannerDesign3 var(--animation-duration) linear infinite;
  position: absolute;
  rotate: -21.85deg;
  z-index: -4;
}

@keyframes nbannerDesign3 {
  0%,
  13.5% {
    width: 5.6875vw;
    height: 5.0549vw;
    bottom: 11.2507vw;
    right: 22.2618vw;
    border-radius: 0.8333vw;
  }

  25%,
  38.5% {
    width: 5.4875vw;
    height: 5.4222vw;
    bottom: 18.2333vw;
    right: 7.1701vw;
    border-radius: 2.9535vw;
  }

  50%,
  63.5% {
    width: 5.3875vw;
    height: 5.1222vw;
    bottom: 24.3035vw;
    right: 6.5701vw;
    border-radius: 0;
  }

  75%,
  88.5% {
    width: 34.7271vw;
    height: 4.634vw;
    bottom: 26.3035vw;
    right: -22.4743vw;
    border-radius: 0;
  }

  100% {
    width: 5.6875vw;
    height: 5.0549vw;
    bottom: 11.2507vw;
    right: 22.2618vw;
    border-radius: 0.8333vw;
  }
}

.newBannerDesign4 {
  background: #ef930345;

  animation: nbannerDesign4 var(--animation-duration) linear infinite;
  position: absolute;
  rotate: -21.85deg;
  z-index: -4;
}

@keyframes nbannerDesign4 {
  0%,
  13.5% {
    width: 3.3625vw;
    height: 3.1174vw;
    bottom: 9.1028vw;
    right: 6.6701vw;
    border-radius: 0.8333vw;
  }

  25%,
  38.5% {
    width: 3.0347vw;
    height: 2.5604vw;
    bottom: 11.7694vw;
    right: 2.0194vw;
    border-radius: 2.059vw;
  }

  50%,
  63.5% {
    width: 3.0347vw;
    height: 2.5604vw;
    bottom: 17.5611vw;
    right: 1.9271vw;
    border-radius: 0;
  }

  75%,
  88.5% {
    width: 9.9319vw;
    height: 2.5604vw;
    bottom: 19.3257vw;
    right: -4.3368vw;
    border-radius: 0;
  }

  100% {
    width: 3.3625vw;
    height: 3.1174vw;
    bottom: 9.1028vw;
    right: 6.6701vw;
    border-radius: 0.8333vw;
  }
}

.newBannerDesign5 {
  background: #ef930345;

  animation: nbannerDesign5 var(--animation-duration) linear infinite;
  position: absolute;
  rotate: -21.85deg;
  z-index: -4;
}

@keyframes nbannerDesign5 {
  0%,
  13.5% {
    width: 5.6875vw;
    height: 5.0549vw;
    bottom: 3.0194vw;
    right: 33.0562vw;
    border-radius: 0.8333vw;
  }

  25%,
  38.5% {
    width: 5.9875vw;
    height: 5.7222vw;
    bottom: 12.8688vw;
    right: 16.9958vw;

    border-radius: 2.9535vw;
  }

  50%,
  63.5% {
    width: 5.3875vw;
    height: 5.3222vw;
    bottom: 18.2021vw;
    right: 14.0958vw;

    border-radius: 0;
  }

  75%,
  88.5% {
    width: 34.7271vw;
    height: 4.634vw;
    bottom: 12.6187vw;
    right: -16.3528vw;
    border-radius: 0;
  }

  100% {
    width: 5.6875vw;
    height: 5.0549vw;
    bottom: 3.0194vw;
    right: 33.0562vw;
    border-radius: 0.8333vw;
  }
}

.newBannerDesign6 {
  background: #ef930345;

  animation: nbannerDesign6 var(--animation-duration) linear infinite;
  position: absolute;
  rotate: -21.85deg;
  z-index: -4;
}
/* .cenConTextCont .cenConTextCont_MainCont .newBannerHLContainer .newBannerccMainHighlight{

    font-weight: 700;
line-height: 15px;
font-size: 12px;


} */

@keyframes nbannerDesign6 {
  0%,
  13.5% {
    width: 8.0153vw;
    height: 7.0056vw;
    bottom: 10.0868vw;
    left: 9.0132vw;
    border-radius: 2.5vw;
  }

  25%,
  38.5% {
    width: 9.7549vw;
    height: 9.366vw;
    bottom: 5.7694vw;
    left: 13.4451vw;
    border-radius: 5.5028vw;
  }

  50%,
  63.5% {
    width: 7.8556vw;
    height: 7.5549vw;
    bottom: 2.2104vw;
    left: 24.6549vw;
    border-radius: 0;
  }

  75%,
  88.5% {
    width: 26.9292vw;
    height: 7.9868vw;
    bottom: 5.6563vw;
    left: -10.9375vw;
    border-radius: 0;
  }

  100% {
    width: 8.0153vw;
    height: 7.0056vw;
    bottom: 10.0868vw;
    left: 9.0132vw;
    border-radius: 2.5vw;
  }
}

.newBannerDesign7 {
  background: #ef930345;

  animation: nbannerDesign7 var(--animation-duration) linear infinite;
  position: absolute;
  rotate: -21.85deg;
}

@keyframes nbannerDesign7 {
  0%,
  13.5% {
    width: 5.3875vw;
    height: 4.7549vw;
    top: 10.3042vw;
    left: -0.3368vw;
    border-radius: 1.1111vw;
  }

  25%,
  38.5% {
    width: 5.3875vw;
    height: 5.1222vw;
    top: 16.1368vw;
    left: -1.3618vw;
    border-radius: 2.8778vw;
  }

  50%,
  63.5% {
    width: 5.3875vw;
    height: 5.1222vw;
    top: 20.2354vw;
    left: 3.0062vw;
    border-radius: 0;
  }

  75%,
  88.5% {
    width: 5.3875vw;
    height: 5.1222vw;
    top: 24.6354vw;
    left: -2.7021vw;
    border-radius: 0;
  }

  100% {
    width: 5.3875vw;
    height: 4.7549vw;
    top: 10.3042vw;
    left: -0.3368vw;
    border-radius: 1.1111vw;
  }
}

/* 
------------- */

@media (max-width: 440px) {
  .newBannerContainer {
    height: 113.8889vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cenConTextCont_MainCont {
    flex-direction: column;
    /* margin-left: 10vw; */

    /* top:20px; */
  }
  .cenConTextCont {
    top: -13vw;
  }

  .cenConTextCont .cenConTextCont_MainCont .newBannerccMainfont {
    font-family: 'Lexend';
    /* font-size: 24px;
    font-weight: 800;
    line-height: 30px;
    text-align: left; */
    /* font-size: 12px;
    line-height: 15px; */
    font-size: 3.333vw;
    line-height: 4.1667vw;

    width: fit-content;
  }

  .cenContImage {
    position: absolute;
    /* width: 339px;
        height: 239.53px; */
    width: 94.1667vw;
    height: 66.5361vw;
  }

  .newBannerHLContainer {
    /* width: 119px;
        height: 17px; */
    width: 33.0556vw;
    height: 4.7222vw;
    /* margin-left:2vw; */
    align-items: center;
    margin-top: 1.1111vw;
  }

  .cenConTextCont
    .cenConTextCont_MainCont
    .newBannerHLContainer
    .newBannerccMainHighlight {
    font-weight: 700;
    /* line-height: 15px;
    font-size: 12px; */
    line-height: 4.1667vw;
    font-size: 3.3333vw;
    text-align: center;
    /* width: 11.3194vw; */
  }

  .cenConSubText {
    /* font-size: 16px; */
    /* font-size: 6.3px; */
    font-size: 2.05vw;
    font-weight: 400;
    /* line-height: 24px; */
    /* line-height: 12px; */
    line-height: 3.333vw;
    text-align: center;
    /* margin-top: 6.36px; */
    margin-top: 1.7667vw;
    /* width: 294px; */

    width: 81.6667vw;
  }

  @keyframes nbannerDesign1 {
    0%,
    13.5% {
      border-radius: 1.9444vw;
      top: 26.6472vw;
      left: 33.0694vw;
      width: 8.2917vw;
      height: 7.4722vw;
    }

    25%,
    38.5% {
      width: 8.2917vw;
      height: 7.8389vw;
      top: 31.925vw;
      left: 17.6319vw;
      border-radius: 5.5028vw;
    }

    50%,
    63.5% {
      width: 8.2917vw;
      height: 7.4722vw;
      top: 38.3333vw;
      left: 10.899vw;
      border-radius: 0;
    }

    75%,
    88.5% {
      width: 48.2139vw;
      height: 11.9611vw;
      top: 25.0153vw;
      left: -20.5812vw;
      border-radius: 0;
    }

    100% {
      border-radius: 1.9444vw;
      top: 26.6472vw;
      left: 33.0694vw;
      width: 8.2917vw;
      height: 7.4722vw;
    }
  }

  @keyframes nbannerDesign2 {
    0%,
    13.5% {
      top: 20.8333vw;
      right: -3.1354vw;
      width: 19.8444vw;
      height: 17.8778vw;
      border-radius: 2.5vw;
    }

    25%,
    38.5% {
      width: 19.8444vw;
      height: 67.43px;
      top: 16.6667vw;
      right: 7.284vw;
      border-radius: 8.9389vw;
    }

    50%,
    63.5% {
      width: 19.8444vw;
      height: 17.8778vw;
      top: 20.8333vw;
      right: 27.709vw;
      border-radius: 0;
    }

    75%,
    88.5% {
      width: 28.2167vw;
      height: 9.5444vw;
      border-radius: 0;
      top: 34.7222vw;
      right: -3.1354vw;
    }

    100% {
      top: 20.8333vw;
      right: -3.1354vw;
      width: 19.8444vw;
      height: 17.8778vw;
      border-radius: 2.5vw;
    }
  }

  @keyframes nbannerDesign5 {
    0%,
    13.5% {
      width: 14.3278vw;
      height: 12.9111vw;
      bottom: 23.0194vw;
      right: 27.0562vw;
      border-radius: 3.3333vw;
    }

    25%,
    38.5% {
      width: 14.3278vw;
      height: 13.4694vw;
      bottom: 27.8688vw;
      right: 11.9958vw;

      border-radius: 6.4556vw;
    }

    50%,
    63.5% {
      width: 14.3278vw;
      height: 12.9111vw;
      bottom: 40.2021vw;
      right: 4.0958vw;

      border-radius: 0;
    }

    75%,
    88.5% {
      width: 42.8222vw;
      height: 14.8556vw;
      bottom: 42.2021vw;
      right: -10.3528vw;
      border-radius: 0;
    }

    100% {
      width: 14.3278vw;
      height: 12.9111vw;
      bottom: 23.0194vw;
      right: 27.0562vw;
      border-radius: 3.3333vw;
    }
  }

  @keyframes nbannerDesign6 {
    0%,
    13.5% {
      width: 19.8444vw;
      height: 17.8778vw;
      bottom: 27.0868vw;
      left: -3.0132vw;
      border-radius: 3.3333vw;
    }

    25%,
    38.5% {
      width: 19.8444vw;
      height: 19.4917vw;
      bottom: 20.7694vw;
      left: 14.4451vw;
      border-radius: 8.9389vw;
    }

    50%,
    63.5% {
      width: 14.65vw;
      height: 13.2vw;
      bottom: 22.2104vw;
      left: 61.225vw;
      border-radius: 0;
    }

    75%,
    88.5% {
      width: 13.3083vw;
      height: 4.9306vw;
      bottom: 39.5361vw;
      left: -8.0889vw;
      border-radius: 0;
    }

    100% {
      width: 19.8444vw;
      height: 17.8778vw;
      bottom: 27.0868vw;
      left: -3.0132vw;
      border-radius: 3.3333vw;
    }
  }

  @keyframes nbannerDesign7 {
    0%,
    13.5% {
      width: 5.4722vw;
      height: 4.9306vw;
      top: 31.8222vw;
      left: 8.4694vw;
      border-radius: 1.1111vw;
    }

    25%,
    38.5% {
      width: 5.4722vw;
      height: 5.4083vw;
      top: 38.4889vw;
      left: -2.7472vw;
      border-radius: 2.4667vw;
    }

    50%,
    63.5% {
      width: 5.4722vw;
      height: 5.4083vw;
      top: 62.6556vw;
      left: 1.4667vw;
      border-radius: 0;
    }

    75%,
    88.5% {
      width: 18.2333vw;
      height: 7.4722vw;
      top: 47.3778vw;
      left: -6.3028vw;
      border-radius: 0;
    }

    100% {
      width: 5.4722vw;
      height: 4.9306vw;
      top: 31.8222vw;
      left: 8.4694vw;
      border-radius: 1.1111vw;
    }
  }
}
