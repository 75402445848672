.switch-container {
  width: 2.3326vw;
  height: 1.084vw;
  background-color: #fff;
  border-radius: 5vw;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px #00000040;
}

.switch-container.on {
  background-color: #fff; /* Switch color when on */
}

.switch-container.off {
  background-color: #fff; /* Switch color when off */
}

.toggle {
  width: 0.859vw;
  height: 0.859vw;
  background-color: #73737361;
  border-radius: 50%;
  position: absolute;
  top: 0.1125vw;
  /* left: 0.2035vw; */
  transition: left 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Adding bounce effect */
}

/* Switch is on, move right and bounce */
.switch-container.on .toggle {
  right: 0.2333vw; /* 10% bounce effect */
}

.switch-container.on .toggle.on {
  background-color: #fbb03b;
  right: 0.2333vw;
}

/* Switch is off, move left and bounce */
.switch-container.off .toggle {
  left: 0.2333vw; /* 10% bounce effect */
}

.switch-container.off .toggle.off {
  background-color: #73737361;
}

/* Bounce effect for toggling to off state */
.bounce-left {
  animation: bounce-left 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

/* Bounce effect for toggling to on state */
.bounce-right {
  animation: bounce-right 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

/* Bounce left 3 times */
@keyframes bounce-left {
  0%,
  100% {
    left: 0.2035vw;
  }
  35% {
    left: 0.2604vw;
  }
  /* 50% { left: 0px; } */
  65% {
    left: 0.1vw;
  }
}

/* Bounce right 3 times */
@keyframes bounce-right {
  0%,
  100% {
    left: 1.2746vw;
  }
  35% {
    left: 1.1757vw;
  }
  /* 50% { left: 60px; } */
  65% {
    left: 1.3146vw;
  }
}

@media screen and (max-width: 440px) {
  .switch-container {
    width: 6.9444vw;
    height: 3.0556vw;
    background-color: #fff;
    border-radius: 9vw;
    margin-top: 1.1vw;
  }
  .toggle {
    width: 2.69vw;
    height: 2.49vw;
    background-color: #73737361;
    border-radius: 50%;
    position: absolute;
    top: 0.2925vw;
    /* left: 0.2035vw; */
    transition: left 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Adding bounce effect */
  }
  .switch-container.on .toggle {
    right: 0.2333vw; /* 10% bounce effect */
  }

  .switch-container.on .toggle.on {
    background-color: #fbb03b;
    right: 0.3333vw;
  }

  /* Switch is off, move left and bounce */
  .switch-container.off .toggle {
    left: 0.3333vw; /* 10% bounce effect */
  }

  .switch-container.off .toggle.off {
    background-color: #73737361;
  }

  .bounce-left {
    animation: bounce-left 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }

  /* Bounce effect for toggling to on state */
  .bounce-right {
    animation: bounce-right 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }

  /* Bounce left 3 times */
  @keyframes bounce-left {
    0%,
    100% {
      left: 0.2035vw;
    }
    35% {
      left: 0.3vw;
    }
    /* 50% { left: 0px; } */
    65% {
      left: 0.21vw;
    }
  }

  /* Bounce right 3 times */
  @keyframes bounce-right {
    0%,
    100% {
      right: 0.1946vw;
    }
    35% {
      right: 0.3157vw;
    }
    /* 50% { left: 60px; } */
    65% {
      right: 0.1846vw;
    }
  }
}
