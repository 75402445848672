.dynamictpContainer {
  width: 91.4479vw;
  height: fit-content;
  margin: 8.5vw 4.3056vw 4.8vw 4.3056vw;
}
.dynamicTPTitle {
  font-family: 'Lexend';
  font-size: 3.3333vw;
  font-weight: 500;
  line-height: 5.3931vw;
  text-align: left;
}

.tpHeading {
  font-family: 'DM Sans';
  font-size: 1.6667vw;
  font-weight: 600;
  line-height: 2.6965vw;
  text-align: left;
  color: var(--KRZ-color-Primary-color, #fbb03b);
  margin: 0 0 1.1111vw 0;
}

.tpContent {
  width: 91.7396vw;
  font-family: 'DM Sans';
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 2.7778vw;
  text-align: justify;
  color: #242626;
  margin: 0;
}

.contentContainer {
  margin: 0 0 3.6984vw 0;
}

.dplistcontainer {
  padding-left: 2.1056vw;
}

@media screen and (max-width: 440px) {
  .dynamictpContainer {
    width: 93.4479vw;
    margin: 16.5vw 3.056vw 4.8vw 3.1667vw;
  }
  .dynamicTPTitle {
    font-family: 'Lexend';
    font-size: 5.7778vw;
    font-weight: 800;
    line-height: 21.3889vw;
  }
  .tpHeading {
    font-family: 'DM Sans';
    font-size: 4.8889vw;
    line-height: 6.2917vw;
  }
  .contentContainer {
    margin-bottom: 5vw;
  }
  .tpContent {
    width: 93.7167vw;

    font-size: 3.7778vw;
    /* font-weight: 400; */
    line-height: 5.5556vw;
    /* margin-bottom: vw; */
  }

  .dplistcontainer {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 6vw;
  }

  .cookiesubhead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4.4444vw;
  }

  .heading1 {
    font-family: 'DM Sans';
    font-size: 3.3333vw;
    font-weight: 600;
    line-height: 5.3944vw;
    /* text-align: left; */
    width: 81.8694vw;
    color: var(--KRZ-color-Primary-color, #fbb03b);
    margin: 0;
  }

  .subcontent {
    width: 75.7333vw;
  }
}

@media screen and (min-width: 1520px) {
  .dynamicTPTitle {
    font-size: 48px;

    line-height: 77.66px;
  }
  .tpHeading {
    font-size: 24px;

    line-height: 38.83px;
  }
  .contentContainer {
    margin-bottom: 2.5vw;
    width: 1321.05px;
  }
  .tpContent {
    font-size: 20px;

    line-height: 40px;
  }

  .dplistcontainer {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 2.5vw;
  }
}
