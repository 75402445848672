.whyKRZButton {
  font-family: 'DM Sans';
  font-size: 1.3889vw;
  font-weight: 600;
  line-height: 3.156vw;
  text-align: center;
  color: var(--KRZ-color-Sub-content-color, #242626);
  background: #fbb03b;
  padding: 0.5556vw 2.2222vw;
  border: none;
  cursor: pointer;
}

.whyKRZButton:hover,
.joinNow:hover,
.morebtn:hover,
.contactusbtn:hover,
.mailtouskrz:hover {
  background: #1b1464;
  color: #ffffff;
  cursor: pointer;
}

/* other css styles */

/* .whyme {
  background-color: rgba(250, 162, 24, 1);
  border-radius: 0;
  font-family: 'Public Sans';
  font-weight: 600;
  padding: 0;
  width: 13.125vw;
  height: 3.9583vw;
  font-size: 1.3889vw !important;
  line-height: 2.8472vw;
  color: rgba(36, 38, 38, 1);
} */

.contactusbtn {
  /* width: 13.2639vw;
  height: 3.9583vw;
  border-radius: 0;
  font-size: 1.8vw !important; */
  margin-left: 28.0785vw;
  /* font-family: 'Public Sans';
  font-size: 2.9173vw;
  background-color: #fbb03b; */
  margin-top: 3vw;
  /* border: none;
  font-size: 1.3889vw !important;
  line-height: 2.8472vw !important;
  color: rgba(27, 20, 100, 1); */
}

.morebtn {
  color: rgba(36, 38, 38, 1);
  background-color: #fbb03b;
  width: 11.8056vw !important;
  height: 3.9583vw !important;
  font-size: 1.3889vw !important;
  font-weight: 600 !important;
  font-family: 'Public Sans';
  line-height: 2.8472vw;
  padding: 0.5vw 2.2vw;
  border: none;
}

.mailtouskrz,
.contactusbtn {
  border: none;
  font-family: 'Public Sans';
  font-weight: 600;
  color: rgba(36, 38, 38, 1);
  background-color: rgba(250, 162, 24, 1);
  width: 9.2361vw;
  height: 3.9583vw;
  font-size: 1.3889vw;
  line-height: 2.8472vw;
}

/* mobile styles */

@media (max-width: 440px) {
  .joinNow {
    font-size: 3.3333vw !important;
    padding: 0;
    background-color: rgba(250, 162, 24, 1);
    margin: 0 35vw;
    width: 21.1111vw;
    height: 8.6111vw;
    border-radius: 0;
    font-family: 'Public Sans';
    font-weight: 600;
    line-height: 6.2639vw;
    color: rgba(36, 38, 38, 1);
  }

  .contactusbtn {
    width: 19.1667vw;
    height: 8.6111vw;
    font-family: 'Public Sans';
    font-weight: 600;
    margin-left: 71.1139vw;
    margin-top: 3vw;
    border: none;
    font-size: 3.3333vw !important;
    line-height: 6.2639vw;
    color: rgba(36, 38, 38, 1);
  }

  .morebtn {
    width: 11.8056vw !important;
    height: 3.9583vw !important;
    font-weight: 600;
    font-size: 1.3889vw !important;
    line-height: 2.8472vw !important;
    font-family: 'DM Sans';
    font-size: 3.8889vw !important;
    margin-left: -110vw;
    color: rgba(36, 38, 38, 1);
    background-color: #fbb03b;
    border: none;
  }

  .mailtouskrz {
    border: none;
    font-family: 'Public Sans';
    font-weight: 600;
    color: rgba(36, 38, 38, 1);
    background-color: rgba(250, 162, 24, 1);
    width: 19.4444vw !important;
    height: 8.6111vw !important;
    font-size: 3.8889vw !important;
    line-height: 6.2639vw !important;
    margin-top: 6.6667vw;
  }
}
