.valuesContainer {
  width: 100%;
  /* padding-top: 9.8194vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.valuesbannerContent {
  width: 53.0556vw;
  height: 15vw;
  margin-top: 17.0375vw;
  margin-left: 5.2076vw;
}

.valuebannermainContent {
  font-family: "Lexend";
  font-size: 3.3333vw;
  font-weight: 800;
  line-height: 4.1667vw;
  text-align: left;
  color: var(--KRZ-color-Title-color, #3f3f3f);
  margin: 0 0 2.2222vw 0;
}

.valuebannersupporttext {
  font-family: "DM Sans";
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 2.2472vw;
  text-align: left;
  color: var(--KRZ-color-Sub-content-color, #242626);
  margin: 0;
  width: 49.2681vw;
}

.custom-list {
  position: relative;
  list-style: none; /* Remove default bullet points */
  padding: 0;
  margin: 0;
  width: 80.1299vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
}

.valuesbannerContainer {
  width: 100%;
  height: 57.8986vw;
  position: relative;
}

.sideImageMount {
  background-image: url("/public/pics/valuesidedesign.svg");
  position: absolute;
  /* width: 90.9743%; */
  right: 0;

  background-position: right;
  background-repeat: no-repeat;
}

.custom-list li {
  position: relative;
  display: flex;
  align-items: flex-start; /* Center-align bullet and content vertically */
  margin-bottom: 6.1111vw; /* Space between list items */
  padding-left: 36.09px; /* Exact space between bullet and content */
  font-size: 1.3889vw; /* Adjust font size */
  color: var(--KRZ-color-Sub-content-color, #242626) /* Text color */;
  width: 75.7139vw;
  flex-direction: column;
}

.custom-list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1.3vw;
  transform: translateY(-50%); /* Align the bullet vertically */
  width: 1.1111vw; /* Bullet size */
  height: 1.1111vw; /* Bullet size */
  background-color: #fbb03b; /* Bullet color */
  border-radius: 50%; /* Makes bullet a circle */
}

/* Add vertical line connecting items */
.custom-list li:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0.4167vw; /* Align with the center of the bullet (half of bullet width) */
  top: 1.3vw; /* Start from the center of the current bullet */
  transform: translateY(0); /* No need to shift the line itself */
  height: calc(100% + 6.1111vw); /* Connects to the center of the next bullet */
  width: 0.2778vw;
  background-color: #faa21836; /* Line color */
}

/* Adjust the last bullet to remove the connection line */
.custom-list li:last-child::after {
  display: none;
  margin: 0;
}

.valuepoint {
  margin: 0;
  font-family: "DM Sans";
  font-size: 1.6667vw;
  font-weight: 400;
  line-height: 3.0556vw;
  text-align: left;
  color: var(--KRZ-color-Sub-content-color, #242626);
}

.highlighted {
  margin: 0;
  color: #ff9d03;
}

.highlightedTitle {
  font-family: "DM Sans";
  font-size: 1.6667vw;
  font-weight: 700;
  line-height: 2.5556vw;
  text-align: left;
  color: #ff9d03;
  margin: 0 0 0.8333vw 0;
}

.workContainer {
  /* background-image: url("/public/pics/workbanner.svg"); */
  width: 100vw;
  height: 36.8993vw;
  /* margin-left: 5vw; */
  background-repeat: no-repeat;
  /* object-fit: cover; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.workContentContainer {
  position: absolute;
  font-family: "DM Sans";
  font-size: 1.6667vw;
  font-weight: 600;
  line-height: 3.75vw;
  text-align: center;
  color: #ffffff;
  width: 71.5514vw;
  margin: 0;
}

.box1 {
  width: 75.1896vw;
  height: 18.1451vw;
  top: 7.9826vw;
  /* left: 10.8229vw; */
  right: -33vw;
  border-radius: 3.8889vw;

  rotate: -21.85deg;
  background: #fbb03b29;
  position: absolute;
  animation: rotation1 16s infinite;
}

@keyframes rotation1 {
  0% {
    width: 75.1896vw;
    height: 18.1451vw;
    top: 7.9826vw;
    /* left: 10.8229vw; */
    right: -33vw;
  }
  25% {
    /* top: 7.9826vw; */
    /* left: 10.8229vw; */
    top: 7.9826vw;
    right: -22vw;
  }
  50% {
    top: 11.9826vw;
    right: -22vw;
  }
  75% {
    top: 7.9826vw;
    /* left: 10.8229vw; */
    right: -37vw;
  }

  100% {
    width: 75.1896vw;
    height: 18.1451vw;
    top: 7.9826vw;
    /* left: 10.8229vw; */
    right: -33vw;
  }
}

.box2 {
  width: 75.1896vw;
  height: 18.1451vw;
  top: 20.9826vw;
  /* left: 10.8229vw; */
  right: -22vw;
  border-radius: 3.8889vw;
  rotate: -21.85deg;
  background: #ef930329;
  position: absolute;
  animation: rotation2 16s infinite;
}

@keyframes rotation2 {
  0% {
    width: 75.1896vw;
    height: 18.1451vw;
    top: 20.9826vw;
    /* left: 10.8229vw; */
    right: -22vw;
  }
  25% {
    top: 20.9826vw;
    /* left: 10.8229vw; */
    right: -22vw;
    /* animation-duration: 2s; */
  }

  50% {
    top: 20.9826vw;
    /* left: 10.8229vw; */
    right: -34vw;
  }
  75% {
    top: 17.9826vw;
    /* left: 10.8229vw; */
    right: -20vw;
  }

  100% {
    width: 75.1896vw;
    height: 18.1451vw;
    top: 20.9826vw;
    /* left: 10.8229vw; */
    right: -22vw;
  }
}

.box3 {
  width: 75.1896vw;
  height: 18.1451vw;
  top: 22.9826vw;
  /* left: 10.8229vw; */
  right: -37vw;
  border-radius: 3.8889vw;
  rotate: -21.85deg;
  background: #ef930329;
  position: absolute;
  animation: rotation3 16s infinite;
}

@keyframes rotation3 {
  0% {
    width: 75.1896vw;
    height: 18.1451vw;
    top: 22.9826vw;
    /* left: 10.8229vw; */
    right: -37vw;
  }
  25% {
    right: -28vw;
    width: 75.1896vw;
    top: 20.9826vw;
  }
  50% {
    top: 26.9826vw;
    right: -18vw;
    width: 74.1896vw;
  }
  75% {
    width: 75.1896vw;
    height: 18.1451vw;
    top: 22.9826vw;
    /* left: 10.8229vw; */
    right: -40vw;
  }

  100% {
    width: 75.1896vw;
    height: 18.1451vw;
    top: 22.9826vw;
    /* left: 10.8229vw; */
    right: -37vw;
  }
}



/* mobile styles */

@media (max-width : 440px) {
  
  .valuebannermainContent{
    width: 58.7389vw;
  height: 6.9444vw;
  font-size: 5.5556vw;
  line-height: 6.9444vw;
  margin-top: 29.4917vw;
  }
.valuesbannerContainer{
  /* width: 80.3361vw;
  height: 55.7500vw; */

}
.valuebannersupporttext{
  width: 80.3361vw;
  height: 38.5278vw;
  padding-top: 5.3333vw;
  font-size: 3.8889vw;
  line-height: 6.2917vw;
}
.box1{
  top:9vw;
  width: 75vw;
  height: 14vw;
  right: -40vw;
}
.box2{
  top: 19vw;
  width: 73vw;
  height: 13vw;
  right: -40vw;
}
.box3{
  top: 16vw;
  width: 82vw;
  height: 13vw;
  right: -40vw;
}
.box1,.box2,.box3{
  /* animation: none; */
  margin-top: -15vw;
  /* width: 70.1722vw;
  height: 13.0611vw; */
  border-radius: 2.2222vw;
  /* width: 54.1722vw;
  height: 13.0611vw; */
}
/* .valboxes{
  width: 65.5556vw;
  height: 41.6667vw;
  margin-top: 0;
} */
.valuepoint{
  width: 84.7222vw;
  /* padding-top: 35vw; */
  font-size: 3.8889vw;
  line-height: 5.5556vw;
}
.custom-list{
  padding-top: 35vw;
  margin-left: -9vw;
}
.custom-list li{
  padding-left: 5vw;
}
.custom-list li::before{
  content: "";
  position: absolute;
  left: -0.5vw;
  top: 2vw;
  transform: translateY(-50%);
  width: 2.9556vw;
  height: 2.9556vw;
  background-color: #fbb03b;
  border-radius: 50%;
}
.custom-list li:not(:last-child)::after {
  width: 1.1111vw;
}
.contactContainer{
  height: 70vw;
}
.highlightedTitle{
  font-size: 4.4444vw;
  line-height: 5.2222vw;
}
.workContentContainer{
  font-size: 3.2333vw;
  line-height: 6.6667vw;
  width: 83.5306vw;
  padding: 20vw 0;
 
  
}
.workContainer{
  height: 63.9389vw;
  position: relative;

}


}