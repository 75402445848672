.navcontainer {
  width: 100%;
  /* height:"12.40%"; */
  /* padding: 0  3.16%; */
  background: transparent;
  position: fixed;
  top: 0;
  z-index: 30;
  transition: transform 0.5s ease-in-out;
}

.navcontainer.hidden {
  transform: translateY(-100%);
}

.navcontainer.visible {
  transform: translateY(0);
}

.flexHorizontalContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* width:"90%"; */
}

.logoTopNav {
  width: 9.14vw !important;
  margin-left: 3.2667vw;
}

.navSectionLink {
  text-decoration: none;
}

.navsection {
  margin: 0.7431vw 4.1667vw 0.7431vw 0;
  padding: 0;
  list-style-type: none;
  width: 27%;
  height: 37.8%;
}

.navlist {
  font-family: "DM Sans";
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #494949;
  padding: 1%;
  cursor: pointer;
  flex: 1;
  width: fit-content;
}

/* .navlistContact{
    
} */

/* .navlighthigh{
    border-bottom: 2px solid #FAA218;
    visibility: hidden;
} */

.navMidList {
  margin: 0% 6% 0% 2%;
}

.navhighlight {
  /* margin-top: 0.0743vw; */
  /* border: 2px solid #FAA218; */
  background: #faa218;
  height: 0.2778vw;
  visibility: hidden;
}

.navhighlight0 {
  width: 3.6583vw;
  visibility: visible !important;
}

.navhighlight1 {
  width: 5.0083vw;
  visibility: visible !important;
}

.navhighlight2 {
  width: 6.6083vw;
  visibility: visible !important;
}

/* mobile designs */

@media screen and (max-width: 440px) {
  .navsection {
    display: none;
  }

  .logoTopNav {
    width: 25.7583vw !important;
    height: 16.3806vw !important;

    margin-left: 2.7028vw;
    margin-top: -0.2667vw;
  }
}

@media screen and (min-width: 1520px) {
  .logoTopNav {
    width: 160.37px;
    height: 80.61px;
    margin-left: 3.9474vw;
    margin-top: 1.5257vw;
  }

  .navlist {
    font-size: 20px;
  }
}
