/* Careers Section */

.careers-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

position: relative;
overflow: hidden;
}

.careers-text-container {
  width: 52.5694vw;
  margin-top: 19.5299vw;
  padding-left: 4.1667vw;
}

.careers-title {
  font-family: "Lexend";
  font-size: 2.5vw;
  line-height: 3.8889vw;
  font-weight: 600;
  color: rgba(73, 73, 73, 1);
}

.careers-content {
  font-family: "DM Sans";
  font-size: 1.3889vw;
  line-height: 2.2472vw;
  font-weight: 400;
  color: rgba(36, 38, 38, 1);
}

.careers-icon {
  width: 25.4167vw;
  margin-top: 15.3632vw;
  padding-right: 8.0556vw;
}

/* Jobvacancies */

.job-vacancies-section {
  font-family: "DM Sans";
  font-size: 2.5vw;
  margin-top: 19.8639vw;
  padding-left: 4.1667vw;
  color: rgba(36, 38, 38, 1);
  line-height: 4.0451vw;
  font-weight: 700;

  position: relative;
overflow: hidden;
}

.search-container {
  /* width: 86.5479vw; */
  width: 90vw;
  height: 5.8083vw;
  margin-left: 4.375vw;
  border-top: 0.1vw solid #d3d3d3;
  border-left: 0.1vw solid #d3d3d3;
  border-bottom: 0.1vw solid #d3d3d3;
  display: flex;
  flex-direction: row;
  border-radius: 0.4vw;
}

.search-icon {
  padding-left: 2.5201vw;
  width: 3.3333vw;
  padding-top: 1.2375vw;
  padding-bottom: 1.2375vw;
}

.job-input {
  outline: none;
  width: 30.125vw;
  padding-left: 1.8479vw;
  padding-top: 1.7931vw;
  padding-bottom: 1.7931vw;
  padding-right: 3vw;
  border: none;
  border-right: 0.1vw solid #d3d3d3;
  font-size: 1.3889vw;
}

.job-input::placeholder {
  font-size: 1.3889vw;
  font-family: "DM Sans";
  color: #d3d3d3;
}

.search-btn {
  background-color: #ffa500;
  /* width: 15.1215vw; */
  width: 20vw;
  color: white;

  margin-left: 1.5vw;
  border-top-right-radius: 0.4vw;
  border-bottom-right-radius: 0.4vw;
  cursor: pointer;
  margin-right: 0.01vw;
  border: none;
  outline: none;
}

.search-btn:hover {
  background-color: #ff8c00;
}

/* Jobopening */

.no-jobs {
  position: relative;
overflow: hidden;


  align-items: center;
  margin: auto;
  flex-direction: column;
  margin-top: 22.25vw;
  width: 53.3132vw;
  display: flex;
  justify-content: center;
  padding-bottom: 30.9063vw;
}

.jobimg {
  width: 11.3889vw;
}

.jobavailability {
  font-size: 1.3889vw;
  font-family: "DM Sans";
  font-weight: 600;
  line-height: 2.2472vw;
}

.highlightjob {
  color: rgba(251, 176, 59, 1);
  font-size: 1.3889vw;
  font-family: "DM Sans";
  font-weight: 600;
  line-height: 2.2472vw;
}

.maillink {
  color: rgba(251, 176, 59, 1);
  text-decoration: underline;
  text-align: center;
  width: fit-content;
}

.maillink:hover {
  text-decoration: underline;
}

/* mobile styles */

@media (max-width: 440px) {
  .careers-title {
    font-size: 5.5556vw;
    line-height: 6.9444vw;
    font-weight: 800;
  }
.careers-section{
  position: relative;
  overflow: hidden;
}
  .careers-text-container {
    padding-top: 15.5611vw;
    width: 90%;
  }

  .careers-content {
    font-size: 3.8889vw;
    line-height: 6.2917vw;
  }

  .careers-icon {
    position: absolute;
    right: -4vw;
    width: 32.5vw;
    margin-top: -20vw;
    padding-right: 0;
  }

  .job-vacancies-section {
    margin-top: 12.8528vw;
    font-size: 5.5556vw;
    font-weight: 600;
    line-height: 8.1111vw;
    font-family: "Lexend";
  }

  .search-container {
    width: 64.5667vw;
    flex-direction: column;
    margin-left: 15.5556vw;
    border: none;
    height: 53.5833vw;
    /* border-radius: 2.5vw; */
  }

  .sdiv {
    display: flex;
    flex-direction: row;
  }

  .job-input,
  .job-input::placeholder {
    border-right: 0;
    line-height: 4.4944vw;
    color: rgba(127, 127, 127, 1);
    font-size: 2.7778vw;
    width: 45vw;
  }

  .search-icon {
    width: 5vw;
  }

  .no-jobs {
    /* margin-left: 19.3917vw; */
    margin-top: -9vw;
    width: 96.9444vw;
  }

  .jobimg {
    width: 12.8889vw;
  }

  .highlightjob,
  .jobavailability,
  .job-notification {
    font-size: 2.7778vw;
    line-height: 4.4944vw;
  }

  .jobavailability {
    width: 86.9444vw;
    padding-left: 52vw;
  }

  .job-notification {
    margin-left: -12vw;
    font-family: "DM Sans";
    font-weight: 600;
    padding-left: 40vw;
    width: 86.9444vw;
  }


}
