.challengeContainer {
  text-align: center;
  width: 100%;
  /* height: 41.5556vw; */
  height: 63.48%;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0; /* Start hidden */
  transform: translateY(10px); /* Start below */
}


.challengeContainer.visible {
  animation: slideIn 1s forwards;
  opacity: 1; /* Become visible */
  transform: translateY(0); /* Move to final position */
}

@keyframes slideIn {
  from {
      transform: translateY(50%); /* Start from below */
      opacity: 0; /* Fully transparent */
  }
  to {
      transform: translateY(0); /* Final position */
      opacity: 1; /* Fully opaque */
  }
}

.challengeTitle {
  font-family: "Lexend";
  font-size: 2.5vw;
  font-weight: 700;
  line-height: 3.125vw;
  color: #494949;
  margin:0;
}

.challengeLists {
  margin-top: 6.3715vw;
  list-style-type: none;
  padding: 0;
  width: 44.516vw;
  height: 29.3vw;
  margin-bottom: 0;
}

.challengeListsimage {
  position: relative;
  top: 0.2vw;
  left: -4vw;

  width: 44.516vw;
  height: 5.175vw;
  transition: left 0.25s ease-out;
}

.challengeListsimage.hovered {
  left: -0.15vw;
  background-size: 100% 0;
  transition: left 0.25s ease-in;
}

.challengeList {
  text-align: left;
  margin-bottom: 3.0417vw;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.listContent {
  position: absolute;
  margin: 0;
  /* left: 11.4653vw; */

  font-family: "Lexend";
  font-size: 2.2222vw;
  font-weight: 700;
  line-height: 3.5958vw;
  /* text-align: left; */
  color: #ffffff;
}

.listContent.defaultlistposition {
  left: 4.3917vw;
}

.listContent.listhovered {
  right: 6.1243vw;
}

.challengeContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.messageContainer {
  margin-top: 14.3007vw;
  width: 41.2576vw;
  margin-right: 8.2229vw;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.indicatore {
  width: 1.1799vw;
  height: 3.1653vw;
  background: #fbb03b;
}

.messageContentContainer {
  margin-left: 1.959vw;
  margin-top: 0.5882vw;
}

.contentTitle {
  margin: 0;
  font-family: "Lexend";
  font-size: 2.2222vw;
  font-weight: 600;
  line-height: 3.5958vw;
  text-align: left;
  color: #737373;
  margin-bottom: 2.2778vw;
  /* opacity: 0.5; */
  transition: opacity 1s ease-out;
}
.contentMesage {
  width: 35.025vw;
  font-family: "DM Sans";
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 3.0556vw;
  text-align: left;
  color: var(--KRZ-color-Sub-content-color, #242626);
  transition: right 1s ease-in;
}

/* mobile styles */

@media (max-width : 440px) {
  .challengeContainer{
    /* margin-bottom: 50vw; */
    width: 100%;
    height: 105.8333vw;
    position: relative;
    top:-10vw;
    /* margin-top: -5vw; */
  }
/* 
  
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 0; 
    transform: translateY(10px); 
  }
  
  
  .challengeContainer.visible {
    animation: slideIn 1s forwards;
    opacity: 1;
    transform: translateY(0); 
  }
  
  @keyframes slideIn {
    from {
        transform: translateY(50%); 
        opacity: 0; 
    }
    to {
        transform: translateY(0); 
        opacity: 1;
    }
  } */


  .dwcbanner{
    z-index: -1;
    position: absolute;
    margin-left: -49vw;
    width: 100%;
    height: 105.7944vw;
  }
  .challengeTitle{
    font-size: 5.5556vw;
    color: white;
    padding-top: 20vw;
    margin-right: 9vw;
  }
  .indicatore{
    background-color: #ffffff;
    width: 1.2944vw;
    height: 8.6056vw;
    /* margin-left: -0.1333vw;*/
    margin-top: -1vw; 
    margin-right: 1.1778vw;
  }
  .challengeLists{
    display: none;
  }
  .messageContainer{
    width: 89.9750vw;
    height: 49.8611vw;
    padding-left: 3vw;
    flex-direction: column;
  }
.contentTitle{
  font-size: 4.4444vw;
    line-height: 7.1917vw;
    color: rgba(63, 63, 63, 1);
}
.messageContentContainer{
  margin-top: -9vw;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.contentMesage{
  font-size: 3.8889vw;
  line-height: 6.2611vw;
  color: white;
  width: 87.5056vw;
  margin-left: 4.6056vw;
}

.challengesButtonsContainer{
  position: absolute;
  bottom: 14.8306vw;
  right: 6.611vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}



}