.bannerTextContainer {
  width: 67%;
  height: 23.21%;
  gap: 0px;
  opacity: 0px;
  margin-left: 5.56vw;
  /* transition: opacity 0.5s ease, transform 0.5s ease; */
}

.banner1TextContainer {
  margin-top: 19.08%;
}

.banner2TextContainer {
  margin-top: 18.44%;
}

.banner3TextContainer {
  margin-top: 18.46%;
  margin-left: 5.45vw !important;
}

.banner4TextContainer {
  margin-top: 18.09%;
  margin-left: 5.45vw !important;
}

.bannerMainText {
  font-family: Lexend;
  font-size: 4.04444vw;
  font-weight: 800;
  line-height: 5.55vw;
  text-align: left;
  margin: 0;
}

.mainTextColor {
  color: #3f3f3f;
}

.spanTextColor {
  color: #fbb03b;
}

.bannerSupportText {
  font-family: DM Sans;
  font-size: 1.38vw;
  font-weight: 400;
  line-height: 2vw;
  text-align: left;
  /* width: 67%;
    height: 46.12%; */

  height: 7.1289vw;
  gap: 0px;
  opacity: 0px;
}
.bannerSupportText1 {
  width: 44.4951vw;
}

.bannerSupportText2 {
  width: 41.9951vw;
}

.bannerSupportText3 {
  width: 40.9951vw;
}

.bannerSupportText4 {
  width: 31.9951vw;
}

.banner1pic {
  /* width: 36.11%;
    height: 49.8%; */
  width: 36.1111vw;
  height: 35.4167vw;
  gap: 0px;
  opacity: 0px;
  rotate: -180 deg;
}

.banner2pic {
  /* width: 530px;
    height: 682px; */
  width: 36.8056vw;
  height: 40.3611vw;
}

.banner3pic {
  /* width: 486px;
    height: 486px; */
  width: 33.65vw;
  height: 33.75vw;
  opacity: 0px;
  rotate: -180 deg;
}

.banner4pic {
  /* width: 738px;
height: 484px; */
  width: 48.6vw;
  height: 46.2656vw;
}

.imageContainer {
  background: transparent;
  /* position: absolute; */
  right: 0;
  top: 0;
  /* flex: 1; */
}

/* .imageContainer img{
    transition: transform 0.5s ease, opacity 0.5s ease;
} */

.imageContainer1 {
  margin-right: 3.0556vw;
  margin-top: 12.2639vw;
}

.imageContainer2 {
  margin-right: 1.1806vw;
  margin-top: 4.718vw;
}

.imageContainer3 {
  margin-right: 0.8028vw;
  margin-top: 7.9258vw;
}

.imageContainer4 {
  margin-right: 0.0689vw;
  margin-top: 5.7945vw;
}

.bannerContainer {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50vw;
  /* overflow-x: hidden; */
  z-index: 20;
  /* top:-2.40vw; */
  background-size: cover;
  background-position: center;
  /* box-sizing: border-box; */
  /* transform: translate(0, 0);
    transition: transform 2s ease-in-out; */
}

.bannerContainer1 {
  background-image: url('/public/pics/banner1bg.svg');
  /* padding: 1.5% 0%  */
}

.bannerContainer2 {
  background-image: url('/public/pics/banner2bg.svg');
  /* align-items: center;
    padding: 1.35% 0% */
}

.bannerContainer3 {
  background-image: url('/public/pics/banner3bg.svg');
  /* padding: 1.55% 0% */
}
.bannerContainer4 {
  background-image: url('/public/pics/banner4bg.svg');
  /* padding: 1.25% 0% */
  object-fit: contain;
}

/* mobile-design */
@media (max-width: 440px) {
  .outer-mobile-container {
    width: 46.875vw;
    /* position: absolute; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 9.6576vw;
  }
  .mob-content-container {
    width: 34.5052vw;
    padding-top: 5.0781vw;
    padding-left: 2.7331vw;
    flex: 1;
  }
  .bannerMainText {
    font-weight: 600;
    font-size: 3.125vw;
    line-height: 3.8008vw;
  }
  .bannerSupportText {
    font-size: 1.6927vw;
    font-weight: 500;
    line-height: 2.0833vw;
  }
  .bannerSupportText1 {
    width: 39.3229vw;
  }
  /* .btn-mob{
          width: 10.5469vw;
      } */
  /* .whyKRZButton {
    width: 10.5469vw;
    font-family: "DM Sans";

    font-size: 1.3889vw;
    font-weight: 600;
    line-height: 3.156vw;
    text-align: center;
    padding: 1.0417vw;
    gap: 1.0417vw;
    background: rgba(251, 176, 59, 1);
   
    border: none;
    cursor: pointer;
  } */

  .box {
    padding-right: 0.112vw;
    width: 10vw;
    position: relative;
  }
  .lg {
    position: absolute;
    /* border: 1px solid black; */
    width: 8.0482vw;
    height: 8.9036vw;
    z-index: 1;
    border-radius: 1.5625vw;
    background: linear-gradient(
      180deg,
      rgba(251, 176, 59, 1),
      rgba(255, 244, 227, 1)
    );
    transform: rotate(-23.69deg);
    /* transition: transform 0.3s ease; */
  }

  .lg-box {
    /* position: absolute; */

    width: 7.7148vw;
    height: 8.5352vw;
    /* margin-top: 1.0130vw; */
    margin-left: 2.1224vw;
    margin-right: 1.6693vw;
    border-radius: 1.5625vw;
    /* z-index: 1; */
    border: 0.1vw solid rgba(251, 176, 59, 1);
    background: linear-gradient(180deg);
    transform: rotate(-23.69deg);
    /* transition: transform 0.3s ease; */
  }
  .lgc {
    position: absolute;
    /* border: 1px solid black; */
    width: 4.8516vw;
    height: 5.2721vw;
    margin-left: -2.9414vw;
    /* z-index: 1; */
    /* margin-right: 43.2552vw; */
    border: 0.1vw solid rgba(251, 176, 59, 1);
    border-radius: 0.5208vw;
    margin-top: 0.6784vw;
    transform: rotate(-23.69deg);
    /* transition: transform 0.3s ease; */
  }

  .lgc-box {
    /* position: absolute; */
    width: 3.8307vw;
    height: 4.237vw;

    /* margin-top: 1.0130vw; */
    margin-left: -2.2669vw;
    margin-right: 1.6693vw;
    border-radius: 0.5208vw;
    z-index: 1;
    background: linear-gradient(
      260deg,
      rgba(251, 176, 59, 1),
      rgba(255, 244, 227, 1)
    );
    /* background: linear-gradient(80deg); */
    transform: rotate(-23.69deg);
    /* transition: transform 0.3s ease; */
  }
}
