.WhyKRZContainer {
  width: 100%;
  margin-top: 15vw;
  height: 46.6326vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.whyKRZImage {
  /* width: 644.47px;
height: 646.17px; */

  width: 44.7549vw;
  height: 44.8729vw;
  margin-right: 4.1549vw;



  opacity: 0; /* Start hidden */
  transform: translateX(-10px); /* Start off-screen to the left */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

}

.WhyKRZContainer.visible .whyKRZImage {
  animation: slideInImage 1s forwards;
  opacity: 1; /* Become visible */
  transform: translateX(0); /* Move to final position */
}

@keyframes slideInImage {
  from {
    transform: translateX(-50px); /* Start from the left */
    opacity: 0; /* Fully transparent */
  }
  to {
    transform: translateX(0); /* Final position */
    opacity: 1; /* Fully opaque */
  }
}
.whyKRZContentContainer {
  width: 48.9583vw;
  height: 25.9722vw;
  margin-top: 6.9313vw;
  margin-right: 4.1667vw;
  text-align: center;

  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0; /* Start hidden */
  transform: translateY(10px); /* Start below */
}


.whyKRZContentContainer.visible {
  animation: slideIn 1s forwards;
  opacity: 1; /* Become visible */
  transform: translateY(0); /* Move to final position */
}

@keyframes slideIn {
  from {
      transform: translateY(50%); /* Start from below */
      opacity: 0; /* Fully transparent */
  }
  to {
      transform: translateY(0); /* Final position */
      opacity: 1; /* Fully opaque */
  }
}

.whyKRZTitle {
  margin: 0 0 3.3333vw 0;
  font-family: 'Lexend';
  font-size: 2.5vw;
  font-weight: 700;
  line-height: 3.125vw;
  text-align: left;
  color: var(--KRZ-color-Title-color, #3f3f3f);
}

.whyKRZContent {
  margin: 0 0 3.3333vw 0;
  font-family: 'DM Sans';
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 3.0556vw;
  text-align: center;
  color: var(--KRZ-color-Sub-content-color, #242626);
  /* margin-bottom: 3.3333vw !important; */
}

@media (max-width: 440px) {
  .whybannero {
    position: absolute;
    z-index: -1;
    width: 100%;
    margin-left: 1vw;
  }
  .WhyKRZContainer {
    margin: 0;
    /* margin-top: 70vw; */
    /* margin-bottom: 40vw; */
    width: 99.9750vw;
    height: 112.9417vw;
  }
  .whyKRZImage {
    margin-top: 3vw;
  
  }
  /* opacity: 0; 
  transform: translateX(-10px); 
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

}

.WhyKRZContainer.visible .whyKRZImage {
  animation: slideInImage 1s forwards;
  opacity: 1; 
  transform: translateX(0); 
}

@keyframes slideInImage {
  from {
    transform: translateX(-50px); 
    opacity: 0; 
  }
  to {
    transform: translateX(0); 
    opacity: 1; 
  }
} */






  .whyKRZTitle {
    font-size: 5.5555vw;
    padding-top: 12vw;
    line-height: 6.9444vw;
  }
  .whyKRZContent {
    padding-top: 15vw;
    /* margin-top: 16.5vw; */
    width: 91.6639vw;
    height: 40.9667vw;
    font-weight: 500;
    line-height: 6.2611vw;
    font-size: 3.8889vw;
    margin-left: -43.5vw;
    color: white;


    
  }

  .krzMoreBTN{
    margin-left:-123vw;
   
  }
}
