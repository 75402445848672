.ContactContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 7.0361vw 5.4882vw 4.5938vw 6.3313vw;
  /* align-items: center; */
}
.cform {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0; /* Start hidden */
  transform: translateY(10px); /* Start below */
  width: fit-content;
  display: flex;
  align-items: center;
}

.cform.visible {
  animation: slideIn 1s forwards;
  opacity: 1; /* Become visible */
  transform: translateY(0); /* Move to final position */
}

@keyframes slideIn {
  from {
    transform: translateY(50%); /* Start from below */
    opacity: 0; /* Fully transparent */
  }
  to {
    transform: translateY(0); /* Final position */
    opacity: 1; /* Fully opaque */
  }
}
.contactusContentContainer {
  width: 36.8056vw;
  margin-top: 9.4326vw;

  opacity: 0; /* Start hidden */
  transform: translateX(-10px); /* Start off-screen to the left */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.ContactContainer.visible .contactusContentContainer {
  animation: slideInImage 1s forwards;
  opacity: 1; /* Become visible */
  transform: translateX(0); /* Move to final position */
}

@keyframes slideInImage {
  from {
    transform: translateX(-50px); /* Start from the left */
    opacity: 0; /* Fully transparent */
  }
  to {
    transform: translateX(0); /* Final position */
    opacity: 1; /* Fully opaque */
  }
}

.contactusContentTitle {
  width: 13.2639vw;
  font-family: 'Lexend';
  font-size: 3.3333vw;
  font-weight: 700;
  line-height: 4.1667vw;
  text-align: left;
  margin: 0;
  color: #494949;
}

.contactusContentHeading {
  font-family: 'DM Sans';
  font-size: 4.4444vw;
  font-weight: 600;
  line-height: 6.4889vw;
  text-align: left;
  margin: 0;
  color: #fbb03b;
}

.contactusContentST {
  font-family: 'DM Sans';
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 2.0278vw;
  text-align: left;
  color: #263238;
}

.contactusFormLabel {
  font-family: 'DM Sans';
  font-size: 1.3889vw;
  font-weight: 500 !important;
  line-height: 2.8472vw;
  text-align: left;
  color: #242626;
}

.compulsaryLabel {
  font-family: 'DM Sans';
  font-size: 1.0667vw;
  font-weight: 400;
  line-height: 1.7167;
  text-align: left;
  color: #b13337;
}

.contactusFormField {
  margin-bottom: 0.5056vw;
}

.requiredFileds {
  font-family: 'DM Sans';
  font-size: 0.9722vw;
  font-weight: 500;
  line-height: 1.9931vw;
  text-align: left;
  color: #b13337;
  padding: 0.2vw 0 0.9vw 0.5vw;
}

.requiredFiledsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.formInput {
  /* border: null; */
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 37.1875vw;
  padding-bottom: 0vw;
  border-color: #dedede;
  font-family: 'DM Sans';
  font-size: 1.1889vw;
  font-weight: 500;
  line-height: 1.5833vw;
  text-align: left;
  color: var(--KRZ-color-sub-content-topic, #737373);
  outline: none;
}

.inputError {
  border-bottom: 0.1389vw solid #b13337;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: light-dark(
    rgb(255, 255, 255),
    rgb(255, 255, 255)
  ) !important;
  color: #737373 !important;
}

.error {
  color: #b13337;
  font-size: 1.0417vw;
  margin: 0.3472vw 0 0 0;
  font-family: 'DM Sans';
  font-weight: 500;
  line-height: 2.1354vw;
  text-align: left;
}

.suberror {
  font-family: 'DM Sans';
  font-weight: 500;
  line-height: 1.566vw;
  text-align: left;

  color: #b13337;
  font-size: 0.7639vw;
  margin: 0.3472vw 0 0 0;
}

.formTextArea {
  padding: 0.8vw 0 0 0.8vw;
  border-radius: 0.8333vw;
  height: 4.9771vw;
  resize: none;
  width: 36.3875vw !important;
  margin-top: 1vw;
  font-size: 0.8333vw !important;
}

.formTextArea::-webkit-scrollbar {
  width: 0.3333vw;
}

.formTextArea::-webkit-scrollbar-thumb {
  background-color: #fbb03b; /* Color of the scrollbar thumb */
  border-radius: 0.6944vw; /* Rounded corners for the thumb */
}

.attachpin {
  width: 1.333vw;
  height: 1.5507vw;
  margin: 0.6868vw 0.7667vw;
}

.attachpinName {
  font-family: 'DM Sans';
  font-size: 1.1111vw;
  font-weight: 600;
  line-height: 2.2778vw;
  text-align: left;
  color: #fbb03b;
}

.attachInfo {
  font-family: 'DM Sans';
  font-size: 1.0417vw;
  font-weight: 400;
  line-height: 1.4583vw;
  text-align: left;
  color: var(--KRZ-color-Sub-content-color, #242626);
  width: 27.0833vw;
  margin-left: 1.3722vw;
}

.formAttachContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25vw;
}

.formAttachClickContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.fileuploadinputfield {
  display: none;
}

.termsAndConditionPara {
  font-family: 'DM Sans';
  font-size: 1.0417vw;
  font-weight: 400;
  line-height: 2.1354vw;
  text-align: center;
  width: 33.6111vw;
  margin-left: 1.2889vw;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin-top:1.3889vw ; */
}

.checkbox {
  width: 1.5278vw;
  height: 1.5278vw;
  margin: 0.6868vw 0 0.6868vw 0.7667vw;
  cursor: pointer;
}

.fileuploadcancelContainer {
  font-family: 'DM San';
  font-size: 1.0417vw;
  font-weight: 600;
  line-height: 2.8472vw;
  text-align: center;
  color: #3f3f3f;
  display: flex;
  flex-direction: row;
  align-items: center;
}

input:-webkit-autofill {
  appearance: menulist-button; /* Sets appearance similar to a select dropdown */
  background-image: none !important;
  background-color: rgb(255, 255, 255) !important; /* Sets a light background */
  -webkit-text-fill-color: #737373 !important; /* Sets text color to a medium gray */
  transition: background-color 5000s ease-in-out 0s; /* Prevents sudden color change */
}


.statusMsg1 {
  display: flex;
  flex-direction: row;
  padding-left: 0.7vw;
}
.tickImg1 {
  width: 1.5278vw;
  height: 1.5278vw;
}
.formMsg1 {
  font-family: "DM Sans";
  font-size: 1.3889vw;
  line-height: 2.8472vw;
  color: green;
font-weight: 600;
  margin-top: -0.65vw;
  padding-left: 1vw;
  margin-bottom: 0;
}

/* mobile styles */

@media (max-width: 440px) {

  .ContactContainer {
    flex-direction: column;
    overflow: hidden;
    /* padding: 29.4917vw 7.4500vw 19.8222vw 3.3333vw */
    /* padding: 0 7.45vw 0vw 3.3333vw; */
    /* padding-left: 3.3333vw; */
    /* padding-bottom: 5.0972vw; */
    padding-bottom: 8.8889vw;
    padding-left: 5.3917vw;
    /* padding-top: 40vw; */
    /* padding-top: 12.6667vw; */
    padding-top: 15.4917vw;
    
  }
  input:-webkit-autofill {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #737373 !important; /* Light color for text */
  }
  .contactusContentContainer {
    width: 89.2167vw;
    /* margin-top: 29.4917vw; */
    margin-bottom: 4.4444vw;
    /* padding-top: 29.4917vw; */
    margin-top: 0;
  }

  .contactusContentHeading {
    display: none;
  }
  .requiredFiledsContainer {
    display: none;
  }
  .contactusContentTitle {
    font-size: 5.5556vw;
    font-weight: 500;
    line-height: 6.9444vw;
    color: rgba(251, 176, 59, 1);
  }
  .contactusContentST {
    font-size: 3.8889vw;
    line-height: 4.8667vw;
    color: rgba(38, 50, 56, 1);
    margin: 0;
    padding-top: 0.5556vw;
  }
  .contactusFormLabel {
    font-size: 3.3333vw;
    line-height: 6.8333vw;
    font-weight: 500;
    color: rgba(115, 115, 115, 1);
  }
  .compulsaryLabel {
    font-size: 4.4444vw;
    line-height: 9.1111vw;
    color: rgba(195, 64, 64, 1);
  }
  .formTextArea {
    font-size: 3.3333vw !important;
    width: 85.6111vw !important;
    height: 23.2722vw;
    line-height: 6.8333vw;
    padding-top: 4vw;
    padding-left: 3.2vw;
    border-radius: 3.3333vw;
  }
  .error,
  .suberror {
    font-size: 2.2222vw;
    line-height: 4.5556vw;
    color: rgba(177, 51, 55, 1);
  }
  .attachInfo {
    font-size: 2.7778vw;
    /* width: 89.3083vw; */
    /* line-height: 3.8889vw; */
    color: rgba(38, 50, 56, 1);
    width: 61.1361vw;
    font-size: 2.5vw;
    font-weight: 400;
    line-height: 3.1111vw;
  }
  .attachpin {
    width: 4.1806vw;
    height: 4.7806vw;
  }
  .attachpinName {
    line-height: 6.8333vw;
    font-size: 3.3333vw;
    width: 23.0556vw;
  }
  .checkbox {
    width: 3.6389vw;
    height: 3.6389vw;
  }
  .termsAndConditionPara {
    font-size: 2.3778vw;
    line-height: 3.8889vw;
    width: 78.4222vw;
  }
  .error,
  .suberror {
    font-size: 2.7778vw !important;
    padding-top: 1.3889vw !important;
    margin: 0 !important;
    line-height: 4vw !important;
  }
  .formInput {
    width: 87.6694vw;
    line-height: 4vw !important;
  }
  /* .whyKRZButton {
    width: 29.1667vw !important;
    height: 8.6111vw !important;
    margin-left: 62.5vw !important;
    font-size: 3.8889vw !important;
    line-height: 6.2639vw !important;
    border-radius: 1.1111vw !important;
  } */

  .fileuploadcancelContainer{
    
font-size: 2.2222vw;

line-height: 4.5556vw;

margin-top: 2.1139vw;
margin-bottom: 0.7139vw;
  }
  .tickImg1 {
    width: 4vw;
    height: 4vw;
    margin-top: 1.3vw;
  }
  .formMsg1 {
    font-size: 2.2222vw;
    line-height: 4.5556vw;
    color: green;
  
    margin-top: 1.3vw;
    padding-left: 1vw;
    margin-bottom: 0;
  }

  .acceptTerms{
    font-size: 2.5vw !important;
  }
}
