.contactContainer {
  height: 54.1444vw;
  width: 100%;
  position: relative;
}

.contactContent {
  padding-top: 16.0382vw;
  margin-left: 3.6542vw;
  width: 45.8347vw;
  position: relative;
}
.contactMainContent {
  font-family: "DM Sans";
  font-size: 4.4444vw;
  font-weight: 600;
  line-height: 6.4889vw;
  text-align: left;
  color: var(--KRZ-color-Title-color, #3f3f3f);
  margin: 0;
  padding-top: 1.1111vw;
  width: 32.2021vw;
}

.contactSupportContent {
  font-family: "DM Sans";
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 2.0278vw;
  text-align: left;
  margin: 0;
  color: #263238;
}

.getInTouchContainer {
  width: 32.0292vw;
  height: 32.0292vw;
  border-radius: 4.1667vw;
  rotate: -46.58deg;
  background-color: #fbb03b;
  box-shadow: 0vw 3.3333vw 6.9444vw 0vw #110c2e26;
  position: absolute;
  margin-top: 12.2313vw;
  right: 0;
  top: 0;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.getInTouchContent {
  rotate: 46.28deg !important;
  font-size: 30px;
  color: #ffffff;
  font-family: "Lexend";
  font-size: 3.3333vw;
  font-weight: 700;
  line-height: 4.0417vw;
  text-align: center;
}

.getInTouchContainer:hover {
  background: linear-gradient(227.01deg, #1b1464 60.67%, #3728ca 112.09%);
}

/* mobile styles */

@media (max-width: 440px) {
  .contactContent {
    /* padding-top: 25.2333vw; */
    width: 47.5vw;
    height: 31.6667vw;
    margin-left: 4.4556vw;
    padding-top: 13.025vw;
  }
  .contactMainContent {
    line-height: 8.1111vw;
    font-family: "Lexend";
    width: 42.1528vw;
    height: 16.1111vw;
    font-size: 5.5556vw;
    font-weight: 600;
    padding-bottom: 1.1111vw;
  }
  .contactSupportContent {
    line-height: 6.2917vw;
    font-weight: 400;
    height: 8.8556vw;
    width: 47.5vw;
    font-size: 3.8889vw;
  }
  .getInTouchContainer {
    width: 40.5194vw;
    height: 40.5194vw;
    border-radius: 6.6667vw;
    margin-top: 13.025vw;
  }
  .getInTouchContent {
    font-size: 5.5556vw;
    line-height: 8.1111vw;
    /* width: 34.5722vw; */
    font-weight: 600;
    color: rgba(65, 65, 65, 1);
  }
}
