.OurValuesContainer {
  /* height: 55.7583vw; */
  width: 100%;
  /* background: url("/public/pics/values-background.svg"); */
  position: relative;
padding-top: 2.7049vw;


  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0; /* Start hidden */
  transform: translateY(10px); /* Start below */
}


.OurValuesContainer.visible {
  animation: slideIn 1s forwards;
  opacity: 1; /* Become visible */
  transform: translateY(0); /* Move to final position */
}

@keyframes slideIn {
  from {
      transform: translateY(50%); /* Start from below */
      opacity: 0; /* Fully transparent */
  }
  to {
      transform: translateY(0); /* Final position */
      opacity: 1; /* Fully opaque */
  }
}


.valuesbackground {
  position: absolute;
  object-fit: cover;
  z-index: -200;
  width: 100%;
}

.overallContent {
  padding-top: 13.3181vw;
  padding-left: 5.6403vw;
  padding-right: 5.2826vw;
  margin-left: 1.1111vw;
  /* margin-top: 17vw; */
}

.ourvaluesTitle {
  font-family: var(--fflex);
  font-size: 2.5vw;
  font-weight: 700;
  line-height: 3.125vw;
  text-align: left;
  margin: 0;
  color: #392159;
}

.ourValuesContent {
  margin-top: 3.3333vw;
  font-family: 'DM Sans';
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 3.0556vw;
  text-align: left;
  width: 64.4479vw;
}

.compValuesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 5.6403vw;
  padding-left: 5.6403vw;
  padding-top: 5vw;
  justify-content: space-between;
}

.valueContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.gifImage {
  /* width: 90px;
height: 86.29px; */
  width: 6.25vw;
  height: 5.9924vw;
  margin-bottom: 2.1493vw;
}

.gifImage:hover {
  color: #fbb03b !important;
}

/* .gifImage::before{
    color: #FBB03B;
} */

.valueName {
  font-family: 'DM Sans';
  font-size: 1.3889vw;
  font-weight: 500;
  line-height: 1.8083vw;
  color: var(--KRZ-color-Sub-content-color, #242626);
  width: 13.125vw;
  height: 3.6111vw;
  text-align: center;
  margin: 0;
}

.valuesblock {
  position: absolute;
  top: 8.7007vw;
  right: 1.2146vw;
  width: 23.6111vw;
  height: 23.6111vw;
}

/* mobile styles  */

@media (max-width: 440px) {
  .compValuesContainer {
    flex-wrap: wrap;
    /* width: 92.5972vw; */
    width: 88.6111vw;
    /* height: 82.6444vw; */
    height: 48.2vw;
    /* margin-top: -8vw; */
    /* align-items: center; */
    /* margin-left: 1vw; */
    justify-content: space-evenly;
padding: 0;
/* padding-left: 2vw; */
  }


  
  .overallContent{
    /* padding-top: 55vw; */
    margin: 0;
    padding: 0;

  }


  .OurValuesContainer {
    height: 82.6444vw !important;
    /* height: 48.2vw; */
    width: 92.5972vw;
    padding: 0;
    padding-left: 4.8222vw;
    padding-top: 7.1417vw;
    top:-10vw;
    /* order: 0; */
  }
    /* transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 0; 
    transform: translateY(10px); 
  }
  
  
  .OurValuesContainer.visible {
    animation: slideIn 1s forwards;
    opacity: 1;
    transform: translateY(0); 
  }
  
  @keyframes slideIn {
    from {
        transform: translateY(50%); 
        opacity: 0; 
    }
    to {
        transform: translateY(0); 
        opacity: 1; 
    }
  }
   */






  .ourvaluesTitle {
    font-size: 5.5555vw;
    line-height: 6.9444vw;
    color: rgba(63, 63, 63, 1);
  }
  .ourValuesContent {
    font-size: 3.8889vw;
    line-height: 6.2611vw;
    width: 92vw;
  }
  .valueName{
    font-size: 3.3333vw;
    line-height: 4.3389vw;
    width: 30vw;
  }

  .valueName0{
    width: 26.944vw;
  }

  .valueName1{
    width: 22.7778vw;
  }
  .valueName2{
    width: 25.5556vw;
  }
  .valueName3{
    width: 29.1667vw;
  }
  .valueName4{
    width: 33.0556vw;
  }
  
/* .compValuesContainer:nth-child(1){
   order: 2; 
} */
.valueContainer:nth-child(1){
  width: 26.9444vw;
}
.valueContainer:nth-child(2){
width: 22.7778vw;
}
.valueContainer:nth-child(3){
  width: 25.5556vw
  }
.valueContainer:nth-child(4){
  width: 29.1667vw;
  }
.valueContainer:nth-child(5){
  width: 33.0556vw;
  }





}
