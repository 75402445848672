body,
html,
#root,
.App {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

:root {
  --ffdm: "DM Sans"; /*font family DM Sans*/
  --fflex: "Lexend"; /*font family Lexend*/
  --fw400: 400; /*font weight 400px*/
  --fw600: 600; /*font weight 600px*/
  --fw800: 800; /*font weight 800px*/
  --fs20: 20px; /*font size 20px*/
  --fs14: 14px; /*font size 14px*/
  --fs12: 12px; /*font size 12px*/
  --fs10: 10px; /*font size 10px*/
  --fsw20_400: 20px /400px; /*shorthand property for font size as 20px and font weight as 400px*/
  --fswff20_400_dm: 20px/400px "DM Sans"; /*shorthand property for font size as 20px, font weight as 400px and font family as DM Sans*/
  --cclr: rgba(36, 38, 38, 1); /*content color of #242626*/
  --hclr: rgba(251, 176, 59, 1); /*highlight color of #FBB03B*/
  --indclr: rgba(250, 162, 24, 1); /*indicator color of #FAA218*/
  --clh44: 44px; /*content line height of 44px*/
  --clh25: 25px; /*content line height of 25px*/
}

/* .contContainerf20 */
