/* Hide elements initially */
.aboutPointContainer .aboutPointContentContainer0,
.aboutPointContainer .aboutPointContentContainer1,
.aboutPointContainer .aboutPointSideDesignDirection0,
.aboutPointContainer .aboutPointSideDesignDirection1 {
  opacity: 0;
  transform: translateX(-100%);
}

/* Trigger animations for both content and side design when parent is visible */
.aboutPointContainer.visible .aboutPointContentContainer0 {
  animation: slideInFromLeft 2.5s forwards;
}

.aboutPointContainer.visible .aboutPointContentContainer1 {
  animation: slideInFromRight 2.5s forwards;
}

.aboutPointContainer.visible .aboutPointSideDesignDirection0 {
  animation: slideInFromRight 2s forwards;
}

.aboutPointContainer.visible .aboutPointSideDesignDirection1 {
  animation: slideInFromLeft 2s forwards;
}

/* Keyframes */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.aboutPointContentContainer0 {
  /* opacity: 0; */
  /* transform: translateX(-100%); */
  /* animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-name: slideInFromLeft;  */
}

.aboutPointContentContainer1 {
  /* opacity: 0; */
  /* transform: translateX(100%); */
  /* animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-name: slideInFromRight;  */
}

.aboutPointSideDesignDirection0 {
  /* opacity: 0; */
  /* transform: translateX(-100%);  */
  /* animation-duration: 2s;
  animation-name: slideInFromLeft; 
  animation-fill-mode: forwards; */
  width: 50.8604vw;
  height: 4.7556vw;
  background: #fbb03b;
}

.aboutPointSideDesignDirection1 {
  /* opacity: 0; */
  /* transform: translateX(100%); */
  /* animation-duration: 2s;
  animation-name: slideInFromRight; 
  animation-fill-mode: forwards; */
  width: 50.8604vw;
  height: 4.7556vw;
  background: #fbb03b;
}

/* heywe */

/* for intersection */
/* Adjust these classes to match the desired animations */
/* .slideInFromLeft {
  animation: slideInLeft 1s ease-out forwards;
}

.slideInFromRight {
  animation: slideInRight 0.6s ease-out forwards;
} */

/* @keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
} */

/* end intersection */

.aboutusContainer {
  padding-top: 12.0694vw;
  /* height: ; */
  width: 100%;
  /* background: url("/public/pics/aboutusBanner.png"); */
  position: relative;
  overflow-x: hidden;
  /* z-index: 400; */
}

.aboutusBannerContainer {
  position: relative;
  /* height: 107.5486vw; */
  height: 95.6229vw;
}

.aboutusBannerBg {
  width: 100%;
  /* height: 107.5486vw; */
  height: 95.6229vw;
  position: absolute;
}

.aboutusBannerTopContentContainer {
  padding: 7.7014vw 0 0 4.1667vw;
  width: 57.5694vw;
}

.aboutusBannerTopContentIntro {
  font-family: "DM Sans";
  font-size: 2.2222vw;
  font-weight: 600;
  line-height: 4.5833vw;
  text-align: left;
  color: var(--KRZ-color-Primary-color, #fbb03b);
  margin: 0;
}

.aboutusBannerTopMainContent {
  font-family: "Lexend";
  font-size: 2.5vw;
  font-weight: 600;
  line-height: 3.8889vw;
  text-align: left;
  width: 45.7639vw;
  margin: -1vw 0 2.2222vw 0;
  color: #494949;
}

.aboutusBannerTopSupportContent {
  font-family: "DM Sans";
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 3.0556vw;
  text-align: left;
  margin: 0;
}

.aboutusBannerBottomContent {
  font-family: "Lexend";
  font-size: 3.3333vw;
  font-weight: 700;
  line-height: 6.3889vw;
  text-align: center;
  margin: 0 4.2535vw;
  bottom: 11.3562vw;
  position: absolute;
  color: #ffffff;

  /* margin-bottom: 11vw; */
}

.aboutusBannerBottomContentHL {
  color: var(--KRZ-color-Title-color, #3f3f3f);
}

.aboutusSecondContainer {
  padding: 5.5556vw 0;
  height: 98.3333vw;
}

.aboutPointContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.aboutPointContainerDirection0 {
  flex-direction: row;
}

.aboutPointContainerDirection1 {
  flex-direction: row-reverse;
}

.aboutPointContentContainer {
  margin: 0 4.1667vw 3.3333vw 4.1667vw;
  width: 39.5833vw;
}

.aboutPointSideDesign {
  width: 50.8604vw;
  height: 4.7556vw;
  background: #fbb03b;
}

.aboutPointHeading {
  font-family: "Lexend";
  font-size: 2.5vw;
  font-weight: 600;
  line-height: 4.5833vw;
  text-align: left;
  color: var(--hclr);
  margin: 0 0 1.1806vw 0;
}

.aboutPointPara {
  font-family: "DM Sans";
  font-size: 1.3889vw;
  font-weight: 400;
  line-height: 3.0556vw;
  text-align: left;
  margin: 0;
}

/* mobile styles */

@media (max-width: 440px) {
  .aboutusContainer {
    overflow-x: hidden;
    padding-top: 0;
    padding-bottom: 13.025vw;
  }
  .aboutusBannerTopContentIntro {
    display: none;
  }
  .aboutusBannerTopContentContainer {
    padding: 0;
    margin-left: 4.0472vw;
  }
  .aboutusBannerTopMainContent {
    font-size: 5.5556vw;
    font-weight: 800;
    width: 64.5889vw;
    margin: 0;
    margin-top: 26.4472vw;
    /* padding-left: 4.0917vw; */
    /* padding-top: 26.3528vw; */
    /* padding-top: 8vw; */
    line-height: 6.9444vw;
  }
  .aboutusBannerTopSupportContent {
    font-size: 3.8889vw;
    width: 93.3222vw;
    line-height: 6.2917vw;
    padding-top: 5vw;
  }
  .aboutusBannerBg {
    width: 51.1972vw;
    height: 51.1972vw;
    /* rotate: -180deg; */
    /* margin-left: 58.35vw; */
    padding-left: 60.35vw;
    margin-top: -26.1361vw;
  }
  .aboutusBannerVision {
    width: 100.0472vw;
    /* position: absolute; */
    margin-top: -10vw;
  }
  .aboutusBannerBottomContent {
    /* bottom: -40vw; */
    font-size: 3.8889vw;
    width: 93.8444vw;
    height: 31.1111vw;
    margin-bottom: -45.9194vw;
  }

  .aboutusSecondContainer {
    margin-top: 37.5vw;
    height: fit-content;
    padding: 0;
  }

  .aboutPointHeaderContainer {
    display: flex;
    align-items: center;
    /* Aligns items vertically in the center */
  }
  .aboutPointContainer {
    justify-content: normal;
    padding-top: 8.8889vw;
  }
  .aboutPointSideDesign {
    margin-left: 2.5vw;
    width: 1.4694vw;
    height: 5.8806vw;
  }
  .aboutPointHeading {
    font-size: 4.4444vw;
    line-height: 12.2222vw;
    margin-top: -3vw;
    margin-left: -2vw;
    width: 90.8333vw;
  }
  .aboutPointPara {
    font-size: 3.8889vw;
    line-height: 6.2917vw;
    width: 90.2389vw;
  }
}
