.dropdown {
  /* border: 1px solid #ccc;
    width: 200px;
    position: relative; */
  border: none;
  /* border-right: 0.1vw solid #d3d3d3; */
  outline: none;
  /* width: 52.5vw; */
  font-size: 1.3889vw;
  font-family: 'DM Sans';
  color: #7f7f7f;
  height: 5.8083vw;
  position: relative;
}
.dropdown-header {
  /* padding: 10px; */
  cursor: pointer;
  background: white;
  /* width: 48.1528vw; */
  width: 42.5vw;
  padding-left: 2.1875vw;
  /* padding-top: 1.4410vw; */
  padding-top: 2vw;
  /* padding-bottom: 1.4410vw; */
  display: flex;
  /* justify-content: space-between; */
  position: relative;
  /* margin-right: 2vw; */
  /* overflow: hidden; */
  /* border: 1px solid #ccc; */
}
.dropdown-list {
  /* border: 1px solid #ccc; */
  /* margin-left: 2vw; */
  /* margin-top: 0.4001vw; */
  padding-left: 2.2813vw;
  position: absolute;
  top:4.25vw;
  font-size: 1.3889vw;
  font-family: 'DM Sans';
  /* width: 100%; */
  background: white;
  border: 0.2vw solid;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2);
  border: none;
  padding-bottom: 1vw;
  z-index: 1;


}
.dropdown-list div {
  /* padding: 10px; */
  padding-top: 2.9861vw;
  padding-right: 1.3vw;
  /* padding-left: 2.2813vw; */
  width: 42.6vw;
  cursor: pointer;
 
}
.dropdown-list div:hover {
  /* background: #f0f0f0; */
  color: #faa218;
}

/* .dropdown::placeholder */

.arrow {
  /* padding-left: 35.9028vw; */
  color: #faa218;
  width: 2.9243vw;
  height: 2.5326vw;
  position: absolute;
  right: 0;
  top:1.6417vw;
  /* margin-right: 1vw; */
}

.job-type-lbl {
  margin: 0;
  color: #d3d3d3;
}

/* phone styles */
@media (max-width: 440px) {
  .job-type-lbl,
  .dropdown-header,
  .dropdown-list {
    font-size: 2.7778vw;
    line-height: 4.4944vw;
    margin: 0;
  }
  .dropdown {
    width: 63.8889vw;
    height: 9.4444vw;
    margin-top: 3vw;
  }
  .dropdown-header {
    width: 59vw;
    color: rgba(127, 127, 127, 1);
    align-items: center;
    /* transform: translateY(-20px); */
  }

  .dropdown-list {
    /* transform: translateY(20px); */
    /* transition: height 0.3s ease; */
    box-shadow: none;
    width: 59vw;
    margin: 0 !important;
    padding-bottom: 0;
    padding-left: 4.5vw;
    color: rgba(127, 127, 127, 1);
    border: 0.1vw solid #d3d3d3;
    /* border-top: 0.1vw solid; */
    border-radius: 3vw;
    top:8vw;
    height: 33vw;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .job-box {
    width: 5vw;
   margin-right: 2.3056vw;
    /* padding-left: 1.5201vw; */
  }

  .dropdown-list div {
    width: 59vw;
  }
  .arrow {
height: 5.1306vw;
width: 5.1306vw;
    }
  }