.animated-text {
  /* object-position: -800px; */
  transition: transform 0.5s, opacity 0.5s;
  opacity: 1; /* Start visible */
  transform: translateY(0); /* Start at normal position */
}

.animated-image {
  transition: transform 1s, opacity 0.9s;
}

/* .animated-image.move-diagonal-up {
    animation: diagonalUp 0.5s forwards;
  }
  
  .animated-image.move-diagonal-down {
    animation: diagonalDown 0.5s forwards;
  }

  @keyframes diagonalUp {
    from {
      transform: translate(0, 0);
      opacity: 1;
    }
    to {
      transform: translate(-100px, -100px);
      opacity: 0;
    }
  }
  
  @keyframes diagonalDown {
    from {
      transform: translate(0, 0);
      opacity: 1;
    }
    to {
      transform: translate(100px, 100px);
      opacity: 0;
    }
  } */

/* Upward movement (scroll up) */
.move-up {
  /* object-position: -500px; */
  transform: translateY(-0%);
  animation: up 2s linear;
  opacity: 0;
}

/* Downward movement (scroll down) - Reverse animation */
.move-up-reverse {
  /* object-position: 500px; */
  transform: translateY(0%);
  opacity: 0;
}

/* Image movement: diagonal-up (scroll up) */
.move-diagonal-up {
  object-position: -70%;
  transform: translate(-150%, -60%);

  opacity: 0;
}

/* Image movement: diagonal-down (scroll down) */
/* .move-diagonal-down {
    object-position: 70%;
    transform: translate(150%, 70%);
    
    opacity: 0;
  } */

/* Initial position for reset on scroll up */
/* .image-initial-position {
    transform: translate(0, 0);
    opacity: 1;
    transition: transform 0.5s, opacity 0.5s;
  } */

/* Indicator styles */
.indicator-container {
  position: absolute; /* Changed to absolute positioning */
  transform: translateX(-50%);
  display: flex;
  gap: 0.5556vw;
  top: 44.4694vw;
  left: 8.9667vw;
  cursor: pointer;
}

.indicator {
  width: 0.6771vw;
  height: 0.4861vw;
  background-color: #d9d9d9;
  transition: transform 2s ease, background-color 1.5s ease;
  position: relative;
}

.indicator.active {
  width: 3.3333vw;
  height: 0.4861vw;
  background-color: #faa218;
  transform: scale(0.4861vw 4.4444vw 0.4861vw 4.4444vw);
  animation: indicatoranimation 0.5s linear;
}

@keyframes indicatoranimation {
  from {
    width: 0.6771vw;
    height: 0.4861vw;
  }
  to {
    width: 3.3333vw;
    height: 0.4861vw;
  }
}

.indicator.reverse {
  background-color: #faa218;
  width: 3.3333vw;
  height: 0.4861vw;
  transform: scale(0.4861vw 4.4444vw 0.4861vw 4.4444vw);
  animation: indicatoranimation 1s linear;
}

/* why */
