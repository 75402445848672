.serviceContainer {
  height: 63.48%;
  text-align: center;
  padding: 1.7049vw 0;
  position: relative;

  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0; /* Initially hidden */
  transform: translateY(10px); /* Start below */
}

.serviceContainer.visible {
  animation: slideIn 1s forwards;
  opacity: 1; /* Become visible */
  transform: translateY(0); /* Move to final position */
}

@keyframes slideIn {
  from {
    transform: translateY(90%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.serviceTitle {
  font-family: 'Lexend';
  font-size: 2.5vw;
  font-weight: 700;
  line-height: 3.125vw;
  text-align: center;
  color: var(--Color-8-Color-81300, #494949);
  margin-bottom: 4.7%;
}

.serviceLists {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
}

.serviceList {
  font-family: 'DM Sans';
  font-size: 1.38vw;
  font-weight: 600;
  line-height: 1.8083vw;
  text-align: center;
  color: #595959;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.serviceListChoosen {
  cursor: default;
}

.serviceListMid {
  margin-left: 4.092vw;
}

.highlight0 {
  width: 14.5094vw;
  visibility: visible !important;
}

.highlight1 {
  width: 9.1694vw;
  visibility: visible !important;
}

.highlight2 {
  width: 3.6083vw;
  visibility: visible !important;
}

.highlight3 {
  width: 7.3618vw;
  visibility: visible !important;
}

.highlight {
  margin-top: 0.87vw;
  background-color: #faa218;
  height: 0.2778vw;
  visibility: hidden;
}

.serviceContentContainer {
  margin-top: 5.8681vw;
  margin-left: 9.0278vw;
  margin-right: 7.7424vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.serviceContentLists {
  list-style: none;
  padding: 0;
  color: #676565;
  display: flex;
  height: 24.667vw;
  flex-wrap: wrap;
  flex-direction: column;
  width: 51.0417vw;
  margin: 0;
}

/* aaa */

.serviceContentAnimate1 {
  animation: opac1 0.8s;
}

.serviceContentAnimate2 {
  animation: opac2 0.8s;
}

@keyframes opac1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pointericon {
  width: 2.3569vw;
  height: 2.4076vw;
}

@keyframes opac2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* 
.pointericon {
  width: 2.3569vw;
  height: 2.4076vw;
} */

/* aaa */

.serviceContentList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3.4743vw;
  width: 23.4326vw;
}
/* 
.pointericon {
  width: 2.3569vw;
  height: 2.4076vw;
} */

.servicePointerList {
  margin-left: 1.4639vw;
  font-family: 'DM Sans';
  font-size: 1.38vw;
  font-weight: 600;
  line-height: 1.6854vw;
}

.servicemessage {
  margin-top: 0.5vw;
  width: 33.2639vw;
  font-family: 'DM Sans';
  font-size: 1.38vw;
  font-weight: 400;
  line-height: 3.0556vw;
  text-align: left;
  color: var(--KRZ-color-Sub-content-color, #242626);
}

/* abs */

.servicemessageanimationup {
  animation: servmessUp 0.5s linear;
}
@keyframes servmessUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.servicemessageanimationupp {
  animation: servmessUpp 0.5s linear;
}
@keyframes servmessUpp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
/* .servicemessageanimationuppp{
   animation: servmessUppp 0.5s linear;
  }
  @keyframes servmessUppp {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
  } */

.servicemessageanimationdownn {
  animation: servmessDownn 0.5s linear;
}
@keyframes servmessDownn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.servicemessageanimationdown {
  animation: servmessDown 0.5s linear;
}
@keyframes servmessDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.serviceMessageCont {
  width: 33.3639vw;
  height: 18.6667vw;
  overflow: hidden;
}

/* abs */

/* mobile styles */

@media (max-width: 440px) {
  .serviceContainer {
    /* padding-top: 30vw; */
    width: 100%;
   height: 110.2222vw;
    padding-bottom: 0;
    overflow: hidden;
    top:-12vw
  }
  .serviceTitle {
    /* padding-top: 5.1278vw; */
    font-size: 5.5556vw;
    line-height: 6.9444vw;

    padding: 0;
    margin: 0;
    margin-bottom: 4vw;
  }

  /* transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(10px); 
}

.serviceContainer.visible {
  animation: slideIn 1s forwards;
  opacity: 1;
  transform: translateY(0);
}

@keyframes slideIn {
  from {
      transform: translateY(90%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
} */
  /* .activeServiceList {
  color: black;
 } */
  /* .serviceListChoosen{
  color: black;
} */
  .highlight {
    display: none;
  }
  .serviceContentContainer {
    flex-direction: column;
    padding-left: 5.3972vw;
    margin-left: 0;
  }
  .serviceContentLists {
    width: 89.3028vw;
    height: 31.6667vw;
    padding-left: 4.0972vw !important;
    margin-left: -4vw;
    align-items: center;
  }
  .serviceList {
    font-size: 3.8889vw;
    line-height: 4.7028vw;
    color: white;
  }
  .serviceLists {
    border: 0.0001px solid #d3d3d3;
    background-color: #fbb03b;
    height: 9.1667vw;
  }
  .servicemessage {
    /* padding-top: 5vw;
    margin-top: -10vw; */
    padding: 0;
    margin: 0;
    font-size: 3.8889vw;
    line-height: 7.1167vw;
    width: 86.9444vw;
    text-align: justify;
    /* margin-left: -5vw; */
  }
  .servicePointerList {
    /* margin-left: -4.5vw;
    width: 80vw; */
    /* width: 32.2222vw; */
    text-align: left;
    font-weight: 600 !important;
    font-size: 3vw !important;
    line-height: 4.0444vw;
  }
  .pointericon {
    width: 4.2583vw;
    height: 4.2583vw;
    /* margin-top: -2.5vw; */
  }
  .serviceContentList {
    width: 50vw;
  }

  .serviceContentList0 {
    width: 36vw;
  }

  .serviceContentList1 {
    width: 32vw;
  }
  .serviceMessageCont {
    width: 100%;
    overflow: hidden;
    height: 41.5vw;
  }
  .serviceListChoosen {
    color: black; /* Active color in mobile view */
  }
  /* 
.activeServiceTitl{
  color: black;
}
.activeServiceList {
  color: black;
 } */

  /* Define the swipe animations */
  @keyframes swipeLeft {
    0% {
      transform: translateX(100px); /* Start off-screen to the right */
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes swipeRight {
    0% {
      transform: translateX(-100px); /* Start off-screen to the left */
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  /* Apply swipe animations conditionally */
  .swipeLeft {
    animation: swipeLeft 0.5s forwards;
  }

  .swipeRight {
    animation: swipeRight 0.5s forwards;
  }

  /* Basic styles for service content container */
  .serviceContentContainer {
    transition: transform 0.5s ease;
  }
}
